import React, {useState, useEffect} from 'react';
import {Grid, Zoom, Paper, Typography} from '@mui/material'
import ReactTimeslotCalendar from '../../components/TimeSlot/js/react-timeslot-calendar';
import moment from 'moment';
import CoachingChart from './AdminItem/CoachingChart';
import CoachingSeancesChart from './AdminItem/CoachingSeancesChart';

export default function AdminDashboard({ zoom, dashboardData }) {
    const [items, setItems] = useState(false)
    const [chartData, setChartData] = React.useState([])
    const [chartSeancesData, setSeancesChartData] = React.useState([])

    useEffect(() => {
        dashboardData && !chartData.length && dashboardData.coachingStates && dashboardData.coachingStates.map((data) => {
            return setChartData(oldChartData => [...oldChartData, {'name': data.name, value: data.value}])
        })
        
        dashboardData && !chartSeancesData.length && dashboardData.monthlySeances && dashboardData.monthlySeances.map((data) => {
            return setSeancesChartData(oldSeancesChartData => [...oldSeancesChartData, {mois: translate(data.month), "Nb séances": data.nbSeancesToCome}])
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardData])

    const handleClick = (data) => {
        setItems(data.seances)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 100 + 200 + 'ms' }}>
                    <Paper elevation={16} sx={{p: 4}}>
                        <Typography variant="h6" color="secondary" sx={{fontWeight: 'bold', mb: 2}}>{moment().format('DD MMMM YYYY')}</Typography>
                        {dashboardData.seances && <ReactTimeslotCalendar isFromAdmin={true} initialDate = {moment().format()} timeslots={[]} seances={dashboardData.seances} handleClick={handleClick} />}
                        {items && items.map(item => (
                            <Typography key={item['@id']} variant="body1" color="secondary" sx={{mt: 2}}> - {moment(item.availability.startAt).format('DD MMMM YYYY')}</Typography>
                        ))}
                    </Paper>
                </Zoom>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 100 + 'ms' }}>
                    <Paper elevation={16} sx={{p: 4, textAlign: 'center'}}>
                        <Typography variant="h6" color="secondary" sx={{fontWeight: 'bold', mb: 2, textAlign: 'center'}}>{moment().format('MMMM YYYY')}</Typography>
                        <Grid container spacing={3} sx={{mt: 1}}>
                            <Grid item xs={12} sm={4}>
                                <div style={{backgroundColor: '#ECA914', fontWeight: 'bold', fontSize: 45, borderRadius: '50%', width: 100, height: 100, margin: 'auto', paddingTop: 15}}>{dashboardData.nbCoachActive}</div><br />
                                Coachs actifs
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{color: 'white', backgroundColor: '#01304A', fontWeight: 'bold', fontSize: 45, borderRadius: '50%', width: 100, height: 100, margin: 'auto', paddingTop: 15}}>{dashboardData.nbCoachingActive}</div><br />
                                Parcours de coaching en cours
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{color: 'white', backgroundColor: '#177EB4', fontWeight: 'bold', fontSize: 45, borderRadius: '50%', width: 100, height: 100, margin: 'auto', paddingTop: 15}}>{dashboardData.nbSeancesToCome}</div><br />
                                Séances prévues
                            </Grid>
                        </Grid>
                    </Paper>
                </Zoom>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 200 + 'ms' }}>
                    <Paper elevation={16} sx={{p: 4}}>
                        {chartSeancesData.length && <CoachingSeancesChart data={chartSeancesData} />}
                    </Paper>
                </Zoom>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 400 + 'ms' }}>
                    <Paper elevation={16} sx={{p: 4}}>
                        {chartData.length && <CoachingChart data={chartData} />}
                    </Paper>
                </Zoom>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: 200 + 'ms' }}>
                    <Paper elevation={16} sx={{p: 4, mt: 3}}>
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="primary" style={{fontSize: 55, textAlign: 'right', fontWeight: 'bold'}}>{dashboardData.unsignedContracts}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" color="secondary" style={{marginTop: 30, textAlign: 'left', fontWeight: 'bold', marginLeft: 65}}>Contrats en attente de signature</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="secondary" style={{fontSize: 55, textAlign: 'right', fontWeight: 'bold'}}>{dashboardData.fullyCompletedCoachings}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="body1" color="secondary" style={{marginTop: 30, textAlign: 'left', fontWeight: 'bold', marginLeft: 65}}>Parcours terminés</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Zoom>
            </Grid>
        </Grid>
    )
}

function translate(data) {
    switch (data) {
        case 'Jun':
            return "Juin"
        case 'Jul':
            return 'Juil'
        case 'Aug':
            return 'Aou'
        case 'Feb':
            return 'Fev'
        case 'Apr':
            return 'Avr'
        case 'May':
            return 'Mai'
        default:
            return data
    }
}