import React, { useState } from 'react';
import cx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Delete, MoreVert } from '@mui/icons-material';
import { IconButton, Menu, tooltipClasses, Zoom } from '@mui/material';
import { MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { TransitionLeft } from '../../helper/utils';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ChatMsg = (props => {
    const [moreIcon, setMoreIcon] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const {
        avatar,
        messages,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
        setNewMessage,
        setIsUdate,
        deleteMessage,
        setOpenSnackbar,
        setText,
        setTransition,
        setSeverity,
        setHasChatSelected,
        hasChatSelected,
        user,
        noAvatar,
    } = props;

/*     const attachClass = index => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return '';
    }; */

    const handleModify = (msg) => {
        setNewMessage(msg.content)
        setIsUdate(msg)
        handleClose()
    }

    const handleDelete = async (msg) => {
        handleClose()
        const deletedMessage = await deleteMessage(msg.uuid)
        if (deletedMessage.discussionDelete.ok && deletedMessage.messages) {
            setHasChatSelected(deletedMessage.messages && deletedMessage.messages['hydra:member'].find((disc) => disc.id === hasChatSelected.id))
            const paper = document.getElementById('box')
            paper.scrollTop = paper.scrollHeight
            setSeverity('success')
            setText('Message supprimé !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setSeverity('error')
            setText('Problème(s) lors de la suppression du message !')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
    }

    const handleIcon = () => {
        setMoreIcon(true)
    }

    const handleDeleteIcon = () => {
        setMoreIcon(false)
    }

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(false)
        setMoreIcon(false)
    }

    return (
        <Grid
            container
            spacing={2}
            justifyContent={side === 'right' ? 'flex-end' : 'flex-start'}
            {...GridContainerProps}
        >
            {side === 'left' && !noAvatar && (
                <Grid item {...GridItemProps}>
                    <StyledTooltip title={user}>
                        <Avatar
                            src={avatar}
                            {...AvatarProps}
                            className={cx(AvatarProps.className)}
                            style={{width: 40, height: 40}}
                        />
                    </StyledTooltip>
                </Grid>
            )}
            <Grid item xs={8}>
                {messages.map((msg, i) => {
                    const TypographyProps = getTypographyProps(msg, i, props);
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={msg.id || i} style={{textAlign: side === 'left' ? 'left' : 'right'}} onMouseEnter={handleIcon} onMouseLeave={handleDeleteIcon}>
                            {side === 'right' && moreIcon && <IconButton onClick={handleClickMenu} style={{marginRight: 5}} ><MoreVert /></IconButton>}
                            <Typography
                                align={'left'}
                                {...TypographyProps}
                                className={cx(
                                    /* attachClass(i), */
                                    TypographyProps.className
                                )}
                                style={side === 'left' ? {
                                    padding: '8px 16px',
                                    borderRadius: 4,
                                    marginBottom: 4,
                                    display: 'inline-block',
                                    wordBreak: 'break-word',
                                    fontSize: '14px',
                                    borderTopRightRadius: 20,
                                    borderBottomRightRadius: 20,
                                    backgroundColor: '#eeeeee',
                                    borderTopLeftRadius: 20,
                                } : {
                                    padding: '8px 16px',
                                    borderRadius: 4,
                                    marginBottom: 4,
                                    display: 'inline-block',
                                    wordBreak: 'break-word',
                                    fontSize: '14px',
                                    borderTopLeftRadius: 20,
                                    borderBottomLeftRadius: 20,
                                    borderTopRightRadius: 20,
                                    backgroundColor: '#ECA914',
                                    color: 'white',
                                }}
                            >
                                {msg.content}
                            </Typography>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleDelete(msg)}><Delete style={{color: 'gray', marginRight: 8}} />Supprimer</MenuItem>
                                <MenuItem onClick={() => handleModify(msg)}><EditIcon style={{color: 'gray', marginRight: 8}} />Modifier</MenuItem>
                            </Menu>
                        </div>
                    );
                })}
            </Grid>
        </Grid>
    );
});

ChatMsg.defaultProps = {
    avatar: '',
    messages: [],
    side: 'left',
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};


export default ChatMsg;
