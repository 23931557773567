import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

export default function MobileAlert({ open, setOpen }) {

    const handleClose = () => {
        setOpen(1800);
    };

  return (
    <div>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
            TransitionComponent={TransitionRight}
            message="Pour profiter d'une meilleur expérience d'utilisation, veuillez télécharger l'application Wellmonday depuis votre store."
        />
    </div>
  );
}