import React from 'react'
import {
    Grid,
} from '@mui/material/';
import Pathway from './StudentItem/Pathway';
import ScheduledSession from './StudentItem/ScheduledSession';
import ToDo from './StudentItem/ToDo';
import SoftSkills from './StudentItem/SoftSkills';

export default function StudentDashboard({dashboardData, zoom, quizHexaco, setOpenBackdrop, setOpenSnackbar, setText, setTransition, setSeverity, }) {
    return (
        <>
            <Pathway zoom={zoom} dashboardData={dashboardData} />
            {dashboardData.currentCoaching && <ScheduledSession zoom={zoom} dashboardData={dashboardData} />}
            <Grid spacing={4} container sx={{mt: 4}}>
                <Grid item xs={12} md={5}>
                    <ToDo zoom={zoom} dashboardData={dashboardData} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
                </Grid>
                <Grid item xs={12} md={7}>
                    {dashboardData && <SoftSkills zoom={zoom} dashboardData={dashboardData} quizHexaco={quizHexaco} />}
                </Grid>
            </Grid>
        </>
    )
}