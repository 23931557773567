import * as React from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    InputLabel,
    Slide,
    useMediaQuery,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Send } from '@mui/icons-material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesMessagePopup = makeStyles((theme) => ({
    messagePaper: {
        width: 900
    },
}));

export default function NewMessage({ open, handleClose }) {
    const theme = useTheme();
    const classes = useStylesMessagePopup()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disable, setDisable] = useState(true)
    const [error, setError] = useState(false)
    const [type, setType] = useState('');

    const handleType = (event) => {
        setType(event.target.value);
    };

    const handleChange = () => {
        setDisable(false)
    }
    
    const handleSendMessage = async (e) => {
        e.preventDefault()
        setDisable(true)
        setError(false)
        setDisable(false)
    }

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="skills-popup-description"
            classes={{paper: classes.messagePaper}}
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A'}}>
                Nouveau Message
            </DialogTitle>
            <DialogContent>
                <form id='messageForm' autoComplete="off" onChange={ handleChange } onSubmit={ handleSendMessage }>
                    <TextField style={{marginTop: 10, marginBottom: 20, width: '100%'}} error={!!error.subject} helperText={error.subject} id="subject" name="subject" label="Sujet" required /><br />
                    <FormControl style={{marginBottom: 20, width: '100%'}} error={!!error.type} required>
                        <InputLabel id="type">Type de message</InputLabel>
                        <Select
                            labelId="type"
                            id="type"
                            label="Type de message"
                            value={type}
                            onChange={handleType}
                        >
                            <MenuItem value={'Information'}>Information</MenuItem>
                            <MenuItem value={'Problème'}>Problème</MenuItem>
                        </Select>
                        {error && <FormHelperText>{error.type}</FormHelperText>}
                    </FormControl><br />
                    <TextField
                        id="message"
                        name="message"
                        label="Votre message"
                        multiline
                        rows={5}
                    
                        required
                        style={{marginBottom: 20, width: '100%'}}
                        error={!!error.messages}
                        helperText={error.messages}
                    /><br />
                    <Button
                        disabled={disable}
                        type='submit'
                        variant="contained"
                        color="primary"
                        endIcon={<Send />}
                    >
                        Envoyer
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}
