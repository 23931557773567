import React from "react";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers'
import "moment/locale/fr";
moment.locale("fr")

export function DateTimePickerComponent({ text, classes, startDate, setValue, error }) {

  return (
    <div style={{marginRight: 20}}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  error={error}
                  value={startDate}
                  disablePast
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  label={text}
                  showTodayButton
                  mask={'__/__/____ __:__'}
                  className={classes}
              />
      </LocalizationProvider>
    </div>
    );
}

export function DatePickerComponent({ text, classes, startDate, setValue, error, color }) {

    return (
      <div style={{marginRight: 20}}>
       <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={text}
                    error={!!error}
                    value={startDate}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    animateYearScrolling
                    className={classes}
                    inputFormat="dd MMMM yyyy"
                    color={color || 'secondary'}
                />
        </LocalizationProvider>
        </div>
    );
  }
