import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import UploadImg from '../../components/UploadImg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCoach({ open, handleClose, handleAdd, error, disable, state, setState, setPhoto}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }
    
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coach-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    Modifier un coach
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="coach-popup-description" component='div'>
                        <TextField
                            error={!!error.description}
                            multiline
                            rows={4}
                            helperText={error.description}
                            style={{
                                marginLeft: 20,
                                marginTop: 20,
                                width: '100%',
                                paddingRight: 35
                            }}
                            label='Description'
                            id='description'
                            name='description'
                            value={state.description}
                            onChange={handleChange}
                        />
                        <div style={{marginTop: 20, marginLeft: 10}}>
                            <UploadImg title={'Modifier la photo de profil'} setPhoto={setPhoto} />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                    <Button onClick={handleAdd} type='submit' disabled={disable}>{'Modifier'}</Button>
                </DialogActions>
        </Dialog>
    );
}
