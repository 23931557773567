export const routes = {
    login: '/login',
    demo: '/demo',
    visio: '/twilio/video/:id/join',
    home: '/',
    dashboard: '/dashboard',
    companies: '/entreprises',
    manageCompanies: '/entreprises/:id',
    coachs: '/coachs',
    profil: '/profil',
    coaching: '/coaching',
    coachingId: '/coaching/:id/entreprise/:company',
    sessions: '/sessions',
    messages: '/messages',
    planning: '/planning',
    parameter: '/parametres',
    updatePassword: '/change-password',
    resetPassword: '/reset-password',
    coachingEvaluation: '/coaching/:id/evaluation',
    toolbox: '/toolbox',
    initializing: '/coaching/:id/initialisation',
    appointment: '/prendre-un-rdv',
    informationCoachings: '/coaching/:id/information',
    validateUser: '/validation/user/:id',
    resetPasswordId: '/reset-password/:id',
    error: '/error',
    manageContent: '/gestion-du-blog',
    addContent: '/gestion-du-blog/ajouter',
    modifyContent: '/gestion-du-blog/modifier',
    manageUsers: '/gestion-des-utilisateurs',
}