import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    Avatar
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CoachPopup({ open, handleClose, handleChoose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coachs-popup-description"
        >
            <Avatar src={open ? open.photo.contentUrl : ''} alt={"Coach"} sx={{margin: 'auto', width: 100, height: 100, mt: 3}} />
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                {open && open.user.firstName} {open && open.user.lastName[0]}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="coachs-popup-description">
                    {open.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
                <Button onClick={handleChoose}>Choisir</Button>
            </DialogActions>
        </Dialog>
    );
}
