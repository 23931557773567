import React, { useState } from 'react'
import {
    Zoom,
    Paper,
} from '@mui/material/';
import moment from 'moment'
import ReactTimeslotCalendar from '../../../components/TimeSlot/js/react-timeslot-calendar'
import NextSeanceInformation from './NextSeanceInformation';

export default function WeekCalendar({ zoom, seances }) {
    const [open, setOpen] = useState(false)

    const handleClick = (seance) => {
        setOpen(seance)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{}}>
                <ReactTimeslotCalendar initialDate = {moment().format()} timeslots={[]} seances={seances} handleClick={handleClick} />
                <NextSeanceInformation open={open} handleClose={handleClose} />
            </Paper>
        </Zoom>
    )
}