import React from 'react'
import { hasRoles } from '../service/security/hasRoles'
import CoachProfil from './ProfilItem/CoachProfil'
import StudentProfil from './ProfilItem/StudentProfil'

export default function Profil({ zoom, user, setOpenSnackbar, setText, setTransition, setSeverity }) {
    document.title = 'Wellmonday - Profil'
    return (
        <>
            {hasRoles() === "coach" && user && <CoachProfil user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
            {(hasRoles() === "student" || hasRoles() === "manager") && user && <StudentProfil user={user} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
        </>
    )
}