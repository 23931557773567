import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_COACHES = 'FETCHING_COACHES'
const SET_COACHES = 'SET_COACHES'
const ADD_COACHES = 'ADD_COACHES'
const UPDATE_COACHES = 'UPDATE_COACHES'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function coachesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_COACHES:
            return { ...state, loading: true }
        case SET_COACHES:
            return {...state, coaches: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_COACHES:
            return {...state, coaches: [action.payload, ...state.coaches]}
        case UPDATE_COACHES:
            return {...state, coaches: action.payload }
        case DELETE_MESSAGE:
            return {...state, coaches: state.coaches.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useCoaches() {
    const [state, dispatch] = useReducer(coachesReducer, {    
        coaches: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        coaches: state.coaches,
        totalItems: state.totalItems,
        view: state.view,
        fetchCoaches: useCallback(async function (route) {
            if ((state.coaches || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_COACHES' })
            const coaches = await apiFetch(route || '/coaches', {method: 'GET'})
            if (coaches) {
                dispatch({type: 'SET_COACHES', payload: coaches})
            }
            return coaches
        }, [state]),
        createCoaches: useCallback(async function(data, route) {
            const newCoaches = await apiFetch('/coaches', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const coaches = await apiFetch(route, {method: 'GET'})
            if (!newCoaches.violations && coaches) {
                dispatch({type: 'SET_COACHES', payload: coaches})
            }
            return newCoaches
        }, []),
        updateCoaches: useCallback(async function(coaches, data, route) {
            const newCoaches = await apiFetch('/coaches/' + coaches, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const coachesUpdated = await apiFetch(route, {method: 'GET'})
            if (!newCoaches.violations && coaches) {
                dispatch({type: 'SET_COACHES', payload: coachesUpdated})
            }
            return newCoaches
        }, []),
        deleteCoaches: useCallback(async function (coachesId) {
            const discussionDelete = await apiFetch('/coaches/' + coachesId, {
                method: 'DELETE'
            }, false, false, true)
            const coachesUpdated = await apiFetch('/coaches', {method: 'GET'})
            if (discussionDelete && coachesUpdated) {
                dispatch({type: 'SET_COACHES', payload: coachesUpdated})
            }
            return discussionDelete
        }, []),
    }
}