import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import AutoCompleteSimpleSelect from '../../components/AutoCompleteSimpleSelect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCoachingPopup({ company, error, disable, open, handleClose, handleAdd, students, setStudent, student, coachs, setCoach, coach, createManagers, createStudents, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChangeFunction = (e) => {
        setCoach(e.target.value)
    }

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="add-coaching-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Veuillez remplir les champs suivants (tous obligatoires)
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="add-coaching-popup-description" component='div'>
                    <AutoCompleteSimpleSelect title={'Salarié *'} dialogTitle='Ajouter un salarié' company={company} data={students} setValue={setStudent} value={student} createStudents={createStudents} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
                    <FormHelperText sx={{color: 'red'}}>{error.student}</FormHelperText>
                    <FormControl sx={{mt: 4, width: '100%'}}>
                        <InputLabel id="select-label">Coach recommandé</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={coach}
                            label="Coach recommandé"
                            onChange={handleChangeFunction}
                        >
                            <MenuItem aria-label="None" value="Pas de coach recommandé" ><em>Pas de coach recommandé</em></MenuItem>
                            {coachs && coachs.length && coachs.map((el, index) => {
                                return <MenuItem key={index} value={el['@id']}>{el.user.lastName} {el.user.firstName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Button sx={{mt: 4, float: 'right'}} variant='contained' onClick={handleAdd}>Ajouter</Button>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={disable} onClick={handleClose}>Fermer</Button>
                <Button disabled={disable} onClick={handleClose}>Terminer</Button>
            </DialogActions>
        </Dialog>
    );
}
