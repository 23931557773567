import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

export default function CloseVisioPopup({ open, handleClose}) {

    return (
        <div style={{zIndex: 9999999}}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Quitter la visio</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez-vous mettre fin à la consultation ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{margin: 'auto'}}>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        Annuler
                    </Button>
                    <Button onClick={(e) => handleClose(e, {isPopup: false, isEndOfVisio: true})} variant="contained" color="primary" autoFocus>
                        terminer la séance
                    </Button>
                </DialogActions>
            </Dialog>
    </div>
    )
}
