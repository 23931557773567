import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    useMediaQuery,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    LinearProgress,
    Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import moment from 'moment'
import { apiFetch } from '../../service/security/apiFetch';
import { useEffect } from 'react';
import { TransitionLeft } from '../../helper/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FollowUpPopup({ open, handleClose, handleRefresh, setOpenSnackbar, setText, setTransition, setSeverity}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sumUp, setSumUp] = useState('')
    const [homeWork, setHomeWork] = useState('')
    const [softSkill, setSoftSkill] = useState('')
    const [hasNote, setHasNote] = useState(false)
    const [loading, setLoading] = useState(false)
    const [softSkills, setSoftSkills] = useState(false)
    const [ressources, setRessources] = useState(false)
    const [ressource, setRessource] = useState('')

    const handleChangeSumUp = (event) => {
        setSumUp(event.target.value);
    };

    const handleChangeHomeWork = (event) => {
        setHomeWork(event.target.value);
    };

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (open && open.props && open.props.noteSeance) {
                const fetchNoteSeance = await apiFetch(`${open.props.noteSeance['@id']}`)
                if (fetchNoteSeance && fetchNoteSeance.uuid) {
                    setHomeWork(fetchNoteSeance.work)
                    setSumUp(fetchNoteSeance.note)
                    setHasNote(true)
                } else {
                    setTransition(() => TransitionLeft)
                    setText('Erreur lors du chargement des données')
                    setSeverity('error')
                    setOpenSnackbar(true)
                    handleClose()
                }
            } else if (open && !softSkills) {
                const fetchSoftSkills = await apiFetch(`/soft_skills`)
                if (fetchSoftSkills && fetchSoftSkills['hydra:member']) {
                    setSoftSkills(fetchSoftSkills['hydra:member'])
                } else {
                    setTransition(() => TransitionLeft)
                    setText('Erreur lors du chargement des données')
                    setSeverity('error')
                    setOpenSnackbar(true)
                    handleClose()
                }
            }
            setLoading(false)
        })()
    }, [open, setTransition, setSeverity, setText, setOpenSnackbar, handleClose, softSkills])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData(e.target)
        const note = data.get('note')
        const homework = data.get('homework')

        let formattedBody = {note: note, work: homework, seance: open.props['@id']}
        if (ressource) formattedBody.resource = ressource
        const noteSeance = await apiFetch('/note_seances', {
            method: 'POST',
            body: JSON.stringify(formattedBody)
        })

        if (noteSeance && noteSeance.id) {
            setTransition(() => TransitionLeft)
            setText('La note de séance a bien été ajoutée !')
            setSeverity('success')
            setOpenSnackbar(true)
            setHomeWork('')
            setSumUp('')
            await handleRefresh()
            handleClose()
        } else {
            setTransition(() => TransitionLeft)
            setText('Erreur lors de l\'ajout de la note !')
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleSelectSoftSkill = async (e) => {
        setSoftSkill(e.target.value)
        if (e.target.value) {
            const getRessources = await apiFetch(`/resources?softSkills.uuid=${e.target.value}`)
            if (getRessources && getRessources['hydra:member']) {
                setRessources(getRessources['hydra:member'])
            } else {
                setTransition(() => TransitionLeft)
                setText('Erreur lors du chargement des données')
                setSeverity('error')
                setOpenSnackbar(true)
                handleClose()
            }
        }
    }

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="followUp-popup-description"
        >
            {open && <div>
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    Suivi de coaching
                </DialogTitle>
                <DialogContent>
                        <form noValidate onSubmit={handleSubmit} autoComplete="off">
                        <Typography variant='h5' color='primary' style={{fontWeight: 'bold', marginBottom: 20}}>{open.coaching.student.user.firstName} {open.coaching.student.user.lastName}</Typography>
                        <div style={{display: 'flex', marginBottom: 20, position: 'relative'}}>
                            <Typography variant='body1' style={{float: 'right'}}><span style={{marginRight: 20}}>Date : </span>{moment(open.props.availability.startAt).format('DD/MM/YYYY')}</Typography>
                        </div>
                        {!loading ? <DialogContentText id="followUp-popup-description" component='div'>
                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>Notes séance du jour :</Typography>
                            <TextField
                                id="multiline-flexible"
                                name="note"
                                label="Résumé de la séance"
                                multiline
                                rows={8}
                                value={sumUp}
                                disabled={hasNote}
                                style={{width: 500}}
                                onChange={handleChangeSumUp}
                            />
                            <Typography variant='h6' color='secondary' style={{fontWeight: 'bold', marginTop: 10, marginBottom: 10}}>Travail demandé pour la prochaine séance :</Typography>
                            <TextField
                                id="multiline-flexible"
                                label="Travail demandé"
                                name="homework"
                                multiline
                                rows={8}
                                value={homeWork}
                                disabled={hasNote}
                                style={{width: 500}}
                                onChange={handleChangeHomeWork}
                            />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} style={{paddingTop: 30}}>
                                    <Typography variant='body1'>Veuillez sélectionner une ressource à consulter</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl style={{marginTop: 10, width: '100%'}}>
                                        <InputLabel id="select-label">Soft skill</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select-soft-skill"
                                            value={softSkill}
                                            label="Soft skill"
                                            onChange={handleSelectSoftSkill}
                                            disabled={hasNote}
                                        >
                                            <MenuItem value="">
                                                <em>Sélectionner une soft skill</em>
                                            </MenuItem>
                                            {softSkills && softSkills.map((softSkill, index) => {
                                                return <MenuItem key={index} value={softSkill.uuid}>{softSkill.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{paddingTop: 30}}>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {softSkill && <FormControl style={{marginTop: 10, width: '100%'}}>
                                        <InputLabel id="select-label">Ressource</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select-ressource"
                                            value={ressource}
                                            label="Ressource"
                                            onChange={(e) => setRessource(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>Sélectionner une ressource</em>
                                            </MenuItem>
                                            {ressources && ressources.map((ressource, index) => {
                                                return <MenuItem key={index} value={ressource['@id']}>{ressource.title}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>}
                                </Grid>
                            </Grid>
                            {!hasNote && <Button type='submit' color='primary' variant='contained'>Enregistrer</Button>}
                        </DialogContentText> : <LinearProgress />}
                    </form>
                </DialogContent>
            </div>}
        </Dialog>
    );
}
