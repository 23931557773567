import { routes } from "../service/routes/routes";
import { Slide, Tooltip, tooltipClasses, Zoom } from "@mui/material";
import { styled } from "@mui/styles";
import { hasRoles } from "../service/security/hasRoles";

export function isKnownRoutes(route, get = false) {
    const r = route.lastIndexOf('/')
    let formattedRoute = route
    if (r > 0) {
        formattedRoute = route.substring(0,r) + '/:id'
    }
    if (get) {
        return formattedRoute
    }
    return Object.keys(routes).find((key) => !!(routes[key] === formattedRoute)) || route.startsWith('/article/'); 
}

export function isNotConnectedRoutes(route) {
    return route === '/login' || route === '/about-us' || route === '/ressources' || route === '/solution' || route === '/blog'; 
}

export function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        marginLeft: 32,
        backgroundColor: theme.palette.primary.main,
    },
}));

export const progressBarWidth = (e, setStyle) => {
    let percentage = 0
    let background = null

    if (e.target.value.length < 4) {
        percentage = 0;
        background = "#dd4b39";
    } else if (e.target.value.length < 6) {
        percentage = 30;
        background = "#dd4b39";
    } else if (e.target.value.length < 8) {
        percentage = 50;
        background = "#ff9800";
    } else {
        percentage = 70;
        background = "#ff9800";
    }

    if ((e.target.value.match(/[a-z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/[A-Z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/0|1|2|3|4|5|6|7|8|9/) != null)) {
        percentage += 10;
    }

    if (percentage === 100) {
        background = "#4caf50";
    }

    setStyle({width: percentage, color: background})
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => {
        return object[key] === value
    });
}

export function translateStatus(status) {
    switch (status) {
        case 'ended':
            return 'Terminé'
        case 'created':
            return 'À venir'
        case 'aborted':
            return 'Annulé'
        case 'running':
            return 'En cours'
        case 'canceled':
            return 'Annulé'
        default:
            return 'En cours'
    }
}

export function getArrayOfSeanceEnded(array) {
    let tab = []
    if (array &&  array.length) {
        array.map((item) => {
            if (item.status === 'ended') {
                tab.push(item)
            }
            return null
        })
    }
    return tab
}

export function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function shouldShowContractsButton(coaching) {
    return hasRoles() === 'coach' && coaching.seances && coaching.seances.length === 1 && coaching.seances[0].status === 'ended' && !coaching.contract && coaching.status === 'running'
}

export function shouldShowChangeCoachButton(coaching) {
    if( coaching.coaching) {
        return coaching.coaching && hasRoles() === 'coach' && coaching.coaching.seances.length === 1 && coaching.coaching.seances[0].status === 'ended' && coaching.coaching.status === 'running' && !coaching.coaching.contract && !coaching.coaching.renewCoachAt && (!coaching.coaching.contract || coaching.coaching.contract.status !== 'completed')
    }
    return coaching && hasRoles() === 'coach' && coaching.seances.length === 1 && coaching.seances[0].status === 'ended' && coaching.status === 'running' && !coaching.contract && !coaching.renewCoachAt && (!coaching.contract || coaching.contract.status !== 'completed')
}

export function shouldShowSignedButton(coaching) {
    return coaching.seances.length === 1 && coaching.contractSigningSessionUrl && coaching.status !== 'ended' && hasRoles() !== 'admin'
}

export function shouldShowCloseButton(coaching) {
    return getArrayOfSeanceEnded(coaching.seances).length === 7 && coaching.status === 'running' && coaching.nbSeancesLeft === 0 && hasRoles() === 'coach'
}

export function shouldShowExtensionButton(coaching) {
    return getArrayOfSeanceEnded(coaching.seances).length === 7 && coaching.status === 'extension requested' && hasRoles() === 'admin'
}

export function shouldShowVisionButton(coaching) {
    return coaching.nextSeance && hasRoles() === 'coach'
}

export function replaceURLs(message) {
    if(!message) return;
    
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?://')) {
            hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
}

export function ConvertStringToHTML (str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
 };

export function findRightImg (item, search) {
    let url = ''
    item.images && item.images.map((image) => {
        if (image.position === search) {
            return url = image.contentUrl
        }
        return false
    })
    return url
}