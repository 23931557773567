import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField,
    FormHelperText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import AutoCompleteSimpleSelect from '../../components/AutoCompleteSimpleSelect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSessionCoachingPopup({ error, disable, open, handleClose, handleAdd, companies, setSessionName, sessionName, setCompany, company, managers, setManager, refresh, func, manager, createManagers, createCompanies, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="add-coachs-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Veuillez remplir les champs suivants (tous obligatoires)
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="add-coachs-popup-description" component='div'>
                    <TextField error={!!error.name} helperText={error.name} color='primary' style={{marginTop: 20, width: '100%'}} label='Nom de la session' id='name' value={sessionName} onChange={(e) => setSessionName(e.target.value)} />
                    <AutoCompleteSimpleSelect title={'Entreprise'} dialogTitle='Ajouter une entreprise' data={companies} setValue={setCompany} value={company} func={func} isCompany={true} createCompanies={createCompanies} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
                    <FormHelperText sx={{color: 'red'}}>{error.company}</FormHelperText>
                    {company && !refresh && managers && <AutoCompleteSimpleSelect company={company} title={'Référent'} dialogTitle='Ajouter un référent' data={managers} setValue={setManager} isManager={true} createManagers={createManagers} value={manager} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />}
                    <FormHelperText sx={{color: 'red'}}>{error.manager}</FormHelperText>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={disable} onClick={handleClose}>Fermer</Button>
                <Button disabled={disable} onClick={handleAdd}>Ajouter</Button>
            </DialogActions>
        </Dialog>
    );
}
