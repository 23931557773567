import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import {  Typography } from '@material-ui/core';

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value.toFixed(2)} %`}</text>
{/*             <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Ratio ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};

export default class CoachingChart extends PureComponent {
    constructor(props) {

        super(props);
    
        this.state = {
          data: this.props.data,
          activeIndex: 0,
        };
    }

    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

  render() {
    return (
        <div>
            <Typography variant="h5" gutterBottom style={{textAlign: 'center', fontSize: 20, color: '#05057c'}}>
                Parcours complétés
            </Typography>
            <ResponsiveContainer height={this.state.data.length * 50 + 100} width="100%">
                <PieChart width={600} height={300}>
                    <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={this.state.data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#01304A"
                        dataKey="value"
                        onMouseEnter={this.onPieEnter}
                        onClick={this.onPieEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
  }
}
