import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPopup({ open, handleClose, handleAdd, error, disable, selected, state, setState, isManager, setManagerFunction, managerFunction, managerFunctions}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleChangeFunction = (e) => {
        setManagerFunction(e.target.value)
    }
    
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coach-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    {selected ? 'Modifier' : 'Ajouter'} un {isManager ? 'manager' : 'coaché'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="coach-popup-description" component='div'>
                        <TextField error={!!error.lastName} helperText={error.lastName} style={{marginLeft: 20, marginTop: 20, marginRight: 60}} label='Nom' id='lastName' name='lastName' value={state.lastName} onChange={handleChange} />
                        <TextField error={!!error.firstName} helperText={error.firstName} style={{marginLeft: 20, marginTop: 20}} label='Prénom' id='firstName' name='firstName' value={state.firstName} onChange={handleChange} />
                        <TextField error={!!error.email} helperText={error.email} style={{marginLeft: 20, marginTop: 20,  marginRight: 60}} label='Email' id='email' name='email' value={state.email} onChange={handleChange} />
                        {isManager && <TextField error={!!error.mobile} helperText={error.mobile} style={{marginLeft: 20, marginTop: 20}} label='N° mobile' id='mobile' name='mobile' value={state.mobile} onChange={handleChange}  />}
                        {isManager && <div style={{marginLeft: 20, marginTop: 20, marginRight: 20, width: '94%'}}>
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Fonction</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    value={managerFunction}
                                    label="Fonction"
                                    onChange={handleChangeFunction}
                                >
                                    <MenuItem aria-label="None" value="" ><em>Sélectionnez une fonction</em></MenuItem>
                                    {managerFunctions && managerFunctions.length && managerFunctions.map((el, index) => {
                                        return <MenuItem key={index} value={el['@id']}>{el.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                    <Button onClick={handleAdd} type='submit' disabled={disable}>{selected ? 'Modifier' : 'Ajouter'}</Button>
                </DialogActions>
        </Dialog>
    );
}
