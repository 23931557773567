import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    useMediaQuery,
    TextField,
    FormHelperText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { FormControl } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExtensionPopup({ open, handleClose, disable, handleAdd, seances, setSeances, reason, setReason, error }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="contract-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Prolongation du coaching
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="contract-popup-description" sx={{m: 2}}>
                    Pour prolonger un coaching vous devez choisir le nombre de séances et saisir un motif.
                </DialogContentText>
                <FormControl error={!!error.suggestedNbSeances} sx={{width: 300, mb: 2}} required>
                    <InputLabel id="nb-coaching-select-label">Nombre de séance(s) suggérée(s)</InputLabel>
                    <Select
                      labelId="nb-coaching-select-label"
                      id="nb-coaching-select"
                      value={seances}
                      label="Nombre de séance(s) suggérée(s)"
                      onChange={(e) => setSeances(e.target.value)}
                    >   
                        <MenuItem value=""><em>Sélectionner un nombre de séance</em></MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                    <FormHelperText sx={{color: 'red'}}>{error.suggestedNbSeances}</FormHelperText>
                </FormControl><br />
                <TextField sx={{width: '100%',}} error={!!error.reason} helperText={error.reason} label='Motif de prolongation' id="reason" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} multiline rows="4" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                <Button onClick={handleAdd} disabled={disable}>Faire la demande</Button>
            </DialogActions>
        </Dialog>
    );
}
