import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField,
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    ListItemText,
    Select,
    Checkbox,
    FormHelperText,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import UploadImg from '../../../components/UploadImg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ManageResourcesPopup({ open, handleClose, handleAdd, error, disable, selected, state, setState, setSoftSkill, softSkills, softSkill, setType, setThemeResource, type, themeResource, themes, types, setDocument, setSoftSkillsRequestable}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleChangeType = (e) => {
        setType(e.target.value);
    }

    const handleChangeTheme = (e) => {
        setThemeResource(e.target.value);
    }

    const handleChangeSoftSkills = (event) => {
        const {
            target: { value },
        } = event;
        let tab = []
        value.map((el) => {
            softSkills.map((item) => {
                if (item.name === el) {
                    tab.push(item['@id'])
                }
                return null
            })
            return null
        })
        setSoftSkillsRequestable(tab)

        setSoftSkill(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coach-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    {selected ? 'Modifier' : 'Ajouter'} une ressource
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="coach-popup-description" component='div'>
                        <TextField error={!!error.title} helperText={error.title} style={{marginLeft: 20, marginTop: 20, marginRight: 60}} label='Titre' id='title' name='title' value={state.title} onChange={handleChange} />
                        <TextField error={!!error.duration} helperText={error.duration} style={{marginLeft: 20, marginTop: 20}} label='Durée' id='duration' name='duration' value={state.duration} onChange={handleChange} />
                        <div>
                            <FormControl sx={{ ml: 2.5, width: '100%', paddingRight: 4.3, mt: 2.5 }}>
                                <InputLabel id="multiple-checkbox-label">Soft Skill</InputLabel>
                                <Select
                                    labelId="multiple-checkbox-label"
                                    id="multiple-checkbox"
                                    multiple
                                    value={softSkill}
                                    onChange={handleChangeSoftSkills}
                                    input={<OutlinedInput label="Soft Skill" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {softSkills && softSkills.map((el) => (
                                        <MenuItem key={el.name} value={el.name} title={el['@id']}>
                                            <Checkbox checked={softSkill.indexOf(el.name) > -1} />
                                            <ListItemText primary={el.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <FormControl sx={{ ml: 2.5, mt: 2.5, minWidth: 220, mr: 10 }} error={error.theme}>
                            <InputLabel id="select-error-label">Thème</InputLabel>
                            <Select
                              labelId="select-error-label"
                              id="select-error"
                              value={themeResource}
                              label="Thème"
                              onChange={handleChangeTheme}
                            >
                                <MenuItem value="">
                                  <em>Sélectionnez un thème</em>
                                </MenuItem>
                                {themes.length && themes.map((el, index) => {
                                    return <MenuItem key={index} value={el['@id']}>{el.name}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{error.theme}</FormHelperText>
                        </FormControl>
                        <FormControl sx={{ mt: 2.5, minWidth: 220 }} error={error.type}>
                            <InputLabel id="select-error-label">Type</InputLabel>
                            <Select
                                labelId="select-error-label"
                                id="select-error"
                                value={type}
                                label="Type"
                                onChange={handleChangeType}
                            >
                                <MenuItem value="">
                                    <em>Sélectionnez un type</em>
                                </MenuItem>
                                {types.length && types.map((el, index) => {
                                    return <MenuItem key={index} value={el['@id']}>{el.name}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText>{error.type}</FormHelperText>
                        </FormControl>
                        <TextField error={!!error.description} helperText={error.description} multiline rows={4} style={{marginLeft: 20, marginTop: 20,  width: '100%', paddingRight: 35}} label='Descriptif' id='description' name='description' value={state.description} onChange={handleChange}  />
                        <div style={{marginTop: 20, marginLeft: 10}}>
                            <UploadImg setPhoto={setDocument} title={'Ajouter un document'} isDoc={true}/>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                    <Button onClick={handleAdd} type='submit' disabled={disable}>{selected ? 'Modifier' : 'Ajouter'}</Button>
                </DialogActions>
        </Dialog>
    );
}
