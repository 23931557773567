import { Fab, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { styled } from "@mui/styles";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { LocalVideoTrack } from 'twilio-video';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';

const Participant = ({setToken, participant, isLocal, blackScreenIsDisplayed, setOpenClosePopup, handleOpen, open, status, notification }) => {
    const CssFabButton = styled(Fab)({
        backgroundColor: '#DC3545',
        color: 'white',
        "&:hover": { 
            backgroundColor: "#DC3545",
            color: 'white'
        },
    });

    const [videoTracks, setVideoTracks] = useState([]);
    const [toggleMic, setToggleMic] = useState(false);
    const [remoteMic, setRemoteMic] = useState(true);
    const [toggleCam, setToggleCam] = useState(false);
    const [audioTracks, setAudioTracks] = useState([]);
    const [screenTracks, setScreenTracks] = useState([]);
    const [seconds, setSeconds] = useState(0);
    let screenTrack;

    const videoRef = useRef();
    const audioRef = useRef();
    const screenRef = useRef();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(
            trackpubsToTracks(participant.videoTracks).filter(
                (track) => track.name !== "user-screen"
            )
        );
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                if (track.name === "user-screen") {
                    setScreenTracks((screenTracks) => [...screenTracks, track]);
                } else {
                    setVideoTracks((videoTracks) => [...videoTracks, track]);
                }
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                if (track.name === "user-screen") {
                    setScreenTracks((screenTracks) => screenTracks.filter((s) => s !== track));
                } else {
                    setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
                }
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            } else if (track.kind === "user-screen") {
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);
        participant.tracks.forEach(publication => {
            publication.on('trackEnabled', () => {
                if (!isLocal) {
                    setRemoteMic(true)
                }
            });
            publication.on('trackDisabled', () => {
                if (!isLocal) {
                    setRemoteMic(false)
                }
            });
        });
        

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            setScreenTracks([]);
            participant.removeAllListeners();
        };
    }, [participant, isLocal]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks, blackScreenIsDisplayed]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    useEffect(() => {
        let interval = null;
        if (!blackScreenIsDisplayed) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
        
    }, [blackScreenIsDisplayed]);

    useEffect(() => {
        const screenTrack = screenTracks[0];
        if (screenTrack) {
          screenTrack.attach(screenRef.current);
          return () => {
            screenTrack.detach();
          };
        }
      }, [screenTracks]);

    const muteMic = () => {
        setToggleMic(!toggleMic)
        !toggleMic ? audioTracks.forEach(track => {
            track.disable();
        }) : audioTracks.forEach(track => {
            track.enable();
        })
    }

    const muteCam = () => {
        setToggleCam(!toggleCam)
        !toggleCam ? videoTracks.forEach(track => {
            track.disable();
        }) : videoTracks.forEach(track => {
            track.enable();
        })
    }

    const beforeLogout = () => {
        setToken(false)
        setOpenClosePopup(true)
    }

    const handleShareScreen = async (isSharing) => {
        if (screenTrack || isSharing) {
/*             console.warn(participant.videoTracks)
            participant.unpublishTrack(screenTrack);
            participant.publishTrack(videoTracks[0]);
            setScreenTracks([])
            screenTrack.stop();

            screenTrack = null; */
            window.location.reload()
        } else {
            const screenStream = await navigator.mediaDevices.getDisplayMedia();
            const track = screenStream.getTracks()[0];
            screenTrack = new LocalVideoTrack(track, {
                name: "user-screen",
            });
            setScreenTracks((screenTracks) => [...screenTracks, screenTrack])
            participant.publishTrack(screenTrack);
            screenTrack.mediaStreamTrack.onended = () => { handleShareScreen() };
        }
    }

    return (
        <div className="participant" style={{color: 'white'}}>
{/*             <h3>{participant.identity}</h3> */}
            {isLocal && <div style={{position: 'absolute', backgroundColor: '#323232', width: open ? 'calc(100% - 350px)' : '100%', padding: 12, height: 79, boxShadow: '0 4px 6px 0 rgb(0 0 0 / 50%)'}}>
                <div style={{display: 'flex', position: 'relative'}}>
                    <div style={{marginTop: 15}}>
                        {!blackScreenIsDisplayed && !!seconds && <Typography variant='body1'>{new Date(seconds * 1000).toISOString().substr(11, 8)}</Typography>}
                    </div>

                    <div style={{position: 'absolute', right: 10, zIndex: 999999999}}>
                        <Fab color={toggleMic ? '' : 'primary'} aria-label="mic" style={{marginRight: 20}} onClick={muteMic}>
                            {!toggleMic ? <MicIcon /> : <MicOffIcon />}
                        </Fab>
                        <Fab color={toggleCam ? '' : "primary"} aria-label="video" style={{marginRight: 20}} onClick={muteCam}>
                            {!toggleCam ? <VideocamIcon /> : <VideocamOffIcon />}
                        </Fab>
                        {/*{notification ? */}
                        {/*    <Fab color={"primary"} aria-label="chat" style={{marginRight: 20}} onClick={handleOpen}>*/}
                        {/*        <Badge color="secondary" variant="dot"><ChatIcon /></Badge>*/}
                        {/*    </Fab>*/}
                        {/*    : <Fab color={"primary"} aria-label="chat" style={{marginRight: 20}} onClick={handleOpen}>*/}
                        {/*    <ChatIcon />*/}
                        {/*</Fab>}*/}
                        <Fab color={"primary"} aria-label="chat" style={{marginRight: 100}} onClick={() => handleShareScreen(screenTracks.length)}>
                            {screenTracks.length ? <StopScreenShareIcon />  : <ScreenShareIcon />}
                        </Fab>
                        <CssFabButton onClick={beforeLogout} aria-label="callEnd" style={{marginRight: 10}}>
                            <CallEndIcon />
                        </CssFabButton>
                    </div>
                </div>
            </div>}
            <div style={{backgroundColor: '#323232'}}>
                <video style={isLocal ? {maxWidth: 283, maxHeight: 283, position: 'absolute', left: 10, top: 100} : {height: window.innerHeight - 6}} ref={screenTracks.length ? screenRef : videoRef} autoPlay={true} />
                <div style={{position: 'absolute', bottom: 10, left: 100}}>
                    {remoteMic ? <MicIcon /> : <MicOffIcon />}
                </div>
            </div>
            {isLocal && <div style={{position: 'absolute', left: 70, top: 320}}>
                {status.statusString}
            </div>}
            <audio ref={audioRef} autoPlay={true} />
        </div>
    );
};

export default Participant;
