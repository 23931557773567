import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    useMediaQuery,
    DialogActions, 
    Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { hasRoles } from '../../service/security/hasRoles';
import MakeAppointment from '../MakeAppointment';
import { TransitionLeft } from '../../helper/utils';
import { apiFetch } from '../../service/security/apiFetch';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EndedVisioPopup({ open, handleClose, setTransition, setSeverity, setText, setOpenSnackbar }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [disable, setDisable] = useState(false)

    const handleChangeCoach = async (e) => {
        setDisable(true)
        const changeCoach = await apiFetch(`/coachings/${open.coachingUuid}/abort`)

        if (changeCoach && changeCoach.success) {
            setTransition(() => TransitionLeft)
            setText('La demande de changement de coach a bien été envoyée !')
            setSeverity('success')
            setOpenSnackbar(true)
            handleClose(e, {isPopup: true, isEndOfVisio: false})
        } else {
            setTransition(() => TransitionLeft)
            setText('Erreur lors de la demande de changement de coach !')
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    }

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="ended-visio-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                {hasRoles() === 'student' ? 'Voulez-vous replanifier une séance ?' : hasRoles() === 'coach' && open.firstSeanceEnded ? 'Le coaché souhaite t\'il poursuivre avec vous le parcours ?' : 'Vous pouvez dès à présent compléter une note de suivi'}
            </DialogTitle>
            <DialogContent>
                {hasRoles() === 'student' ? <MakeAppointment handleClose={handleClose} isFromPopup={true} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setOpenSnackbar={setOpenSnackbar} zoom={true} /> : 
                open.firstSeanceEnded && <div style={{display: 'flex',}}>
                    <Button disabled={disable} variant='contained' color='primary' onClick={(e) => handleClose(e, {isPopup: true, isEndOfVisio: false})} sx={{ml: 2,mr: 2}}>Oui</Button>
                    <Button disabled={disable} variant='contained' color='primary' onClick={handleChangeCoach}>Non, il souhaite changer de coach</Button>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button disabled={disable} onClick={(e) => handleClose(e, {isPopup: true, isEndOfVisio: false})}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
}
