import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageExtensionPopup({ open, handleClose, disable, handleAdd, seances, setSeances, setOpen}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={() => setOpen(false)}
            aria-describedby="contract-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Prolongation du coaching
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="contract-popup-description" sx={{m: 2}}>
                    Vous pouvez accepter ou refuser la demande de prolongation de coaching. Si vous acceptez, veuillez indiquer le nombre de séances acceptés.
                </DialogContentText>
                <FormControl sx={{width: 300, mb: 2, mt: 4}} required>
                    <InputLabel id="nb-coaching-select-label">Nombre de séance(s) suggérée(s)</InputLabel>
                    <Select
                      labelId="nb-coaching-select-label"
                      id="nb-coaching-select"
                      value={seances}
                      label="Nombre de séance(s) suggérée(s)"
                      onChange={(e) => setSeances(e.target.value)}
                    >   
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
                {open.coaching && open.coaching.extensionRequest && <Typography variant="body1"><span style={{color: '#01304A', fontWeight: 'bold'}}>Raison : </span>{open.coaching.extensionRequest.reason}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={disable}>Refuser</Button>
                <Button onClick={handleAdd} disabled={disable}>Accepter</Button>
            </DialogActions>
        </Dialog>
    );
}
