import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FormControl, InputLabel, MenuItem, Select, Button, DialogActions, DialogContent, DialogTitle, Dialog, TextField } from '@mui/material';
import { useEffect } from 'react';
import { apiFetch } from '../service/security/apiFetch';
import { handleFormError } from '../service/security/error';
import { TransitionLeft } from '../helper/utils';

const filter = createFilterOptions();

export default function AutoCompleteSimpleSelect({ isManager, title, dialogTitle, data, setValue, value, func, isCompany, createManagers, createStudents, createCompanies, company, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [open, toggleOpen] = React.useState(false);
    const [managerFunction, setManagerFunction] = React.useState('');
    const [managerFunctions, setManagerFunctions] = React.useState(false);
    const [error, setError] = React.useState({});
    const [disable, setDisable] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState({
        name: '',
        address: '',
        lastName: '',
        firstName: '',
        email: '',
        mobile: '',
        function: '',
    });

    const handleClose = () => {
        setDialogValue({
            name: '',
            address: '',
            lastName: '',
            firstName: '',
            email: '',
            mobile: '',
            function: '',
        });

        toggleOpen(false);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisable(true)
        setError({})
        if (isCompany) {
            let body = { name: dialogValue.name, address: dialogValue.address }
            
            const manage = await createCompanies(body, '/companies')
            if (manage && manage.uuid) {
                setText('L\'entreprise a été créée')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setValue(manage);
                func && func(manage)
                handleClose();
            } else {
                const formError = handleFormError(manage, ['name', 'address'])
                setError(formError)
                setSeverity('error')
                setText('Erreur lors de l\'ajout d\'une entreprise !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else if (isManager) {
            const firstName = dialogValue.firstName
            const lastName = dialogValue.lastName
            const email = dialogValue.email
            const mobile = dialogValue.mobile
    
            const body = {user: {firstName: firstName, lastName: lastName, email: email }, mobile: mobile, function: managerFunction, company: `/companies/${company.uuid}`}
    
            const manage = await createManagers(body, `/managers?company.uuid=${company.uuid}`)
            if (manage && manage.uuid) {
                setText('Le Manager a été créé')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setValue(manage);
                handleClose();
            } else {
                const formError = handleFormError(manage, ['user.lastName', 'user.firstName', 'user.email', 'user.mobile', 'function.name'])
                setError(formError)
                setSeverity('error')
                setText('Erreur lors de l\'ajout de l\'utilisateur !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else {
            const firstName = dialogValue.firstName
            const lastName = dialogValue.lastName
            const email = dialogValue.email
    
            const body = {user: {firstName: firstName, lastName: lastName, email: email }, company: `/companies/${company}`}
    
            const manage = await createStudents(body, `/students?company.uuid=${company}`)
            if (manage && manage.uuid) {
                setText('Le coaché a été créé')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setValue(manage);
                handleClose();
            } else {
                const formError = handleFormError(manage, ['user.lastName', 'user.firstName', 'user.email'])
                setError(formError)
                setSeverity('error')
                setText('Erreur lors de l\'ajout de l\'utilisateur !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        }
        setDisable(false)
    };

    const handleChangeFunction = (e) => {
        setManagerFunction(e.target.value)
    }

    useEffect(() => {
        (async () => {
            const fetchFunction = await apiFetch(`/manager_functions`)
            if (fetchFunction && fetchFunction['hydra:member']) {
                setManagerFunctions(fetchFunction['hydra:member'])
            }
        })()
    }, [])

    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                name: newValue,
                                lastName: newValue,
                                address: '',
                                firstName: '',
                                email: '',
                                mobile: '',
                                function: '',
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            name: newValue.inputValue,
                            lastName: newValue.inputValue,
                            address: '',
                            firstName: '',
                            email: '',
                            mobile: '',
                            function: '',
                        });
                    } else {
                        setValue(newValue);
                        func && func(newValue)
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Ajouter "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id="autocomplete-simple-select"
                options={data}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name || (option.user && `${option.user.lastName} ${option.user.firstName}: ${option.user.email}`);
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.name || (option.user && `${option.user.lastName} ${option.user.firstName}: ${option.user.email}`)}</li>}
                sx={{ width: '100%', marginTop: 4 }}
                freeSolo
                renderInput={(params) => <TextField {...params} label={title} />}
            />
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>{dialogTitle}</DialogTitle>
                    <DialogContent style={{paddingTop: 15}}>
                        {isCompany ? <>
                            <TextField
                                autoFocus
                                id="name"
                                value={dialogValue.name}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        name: event.target.value,
                                    })
                                }
                                label="Nom"
                                type="text"
                                error={!!error.name}
                                helperText={error.name}
                                sx={{mr: 4, width: 244}}
                            />
                            <TextField
                                id="address"
                                value={dialogValue.address}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        address: event.target.value,
                                    })
                                }
                                label="Adresse"
                                type="text"
                                error={!!error.address}
                                helperText={error.address}
                                sx={{width: 244}}
                            />
                        </> : <>
                            <TextField
                                autoFocus
                                id="lastName"
                                value={dialogValue.lastName}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        lastName: event.target.value,
                                    })
                                }
                                label="Nom"
                                type="text"
                                error={!!error.lastName}
                                helperText={error.lastName}
                                sx={{mr: 8, width: 244}}
                            />
                            <TextField
                                id="firstName"
                                value={dialogValue.firstName}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        firstName: event.target.value,
                                    })
                                }
                                label="Prénom"
                                type="text"
                                error={!!error.firstName}
                                helperText={error.firstName}
                                sx={{width: 244}}
                            />
                            <TextField
                                id="email"
                                value={dialogValue.email}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        email: event.target.value,
                                    })
                                }
                                label="Email"
                                type="text"
                                error={!!error.email}
                                helperText={error.email}
                                sx={{mr: 8, mt: 4, width: 244}}
                            />
                            {isManager && <TextField
                                id="mobile"
                                value={dialogValue.mobile}
                                onChange={(event) =>
                                    setDialogValue({
                                        ...dialogValue,
                                        mobile: event.target.value,
                                    })
                                }
                                label="N° mobile"
                                type="text"
                                error={!!error.mobile}
                                helperText={error.mobile}
                                sx={{mt: 4, width: 244}}
                            />}<br />
                            {isManager && <FormControl sx={{mt: 4, width: '100%'}}>
                                <InputLabel id="select-label">Fonction</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    value={managerFunction}
                                    label="Age"
                                    onChange={handleChangeFunction}
                                >
                                    <MenuItem aria-label="None" value="" ><em>Sélectionnez une fonction</em></MenuItem>
                                    {managerFunctions && managerFunctions.length && managerFunctions.map((el, index) => {
                                        return <MenuItem key={index} value={el['@id']}>{el.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>}
                        </>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disable} onClick={handleClose}>Annuler</Button>
                        <Button disabled={disable} type="submit">Ajouter</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}