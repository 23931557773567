import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    Avatar,
    Badge,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import UploadImg from '../../components/UploadImg';
import { hasRoles } from '../../service/security/hasRoles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoPopup({ open, handleClose, handleChoose, setPhoto, avatars, photo, disable}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event) => {
        setPhoto(event.target.value);
    };

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coachs-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Changer d'avatar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="coachs-popup-description" component='div'>
                    {hasRoles() !== 'coach' && <div style={{display: 'flex', flexWrap: 'wrap', paddingLeft: 24}}>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group"
                            name="radio-buttons-group"
                            value={photo}
                            onChange={handleChange}
                            style={{display: 'block'}}
                        >
                            {avatars && avatars.map((el, i) => {
                                return (<Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <FormControlLabel value={el['@id']} control={<Radio />} label="" />
                                    }
                                    key={i}
                                >
                                    <Avatar src={el.contentUrl} sx={{width: 100, height: 100, mr: 4, mt: 4, border: "1px solid black"}} />
                                </Badge>
                            )})}
                        </RadioGroup>
                    </div>}
                    <div style={{marginTop: 40}}>
                        <UploadImg title='Ajouter/Changer une photo' setPhoto={setPhoto} />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={disable} onClick={handleClose}>Fermer</Button>
                <Button disabled={disable} onClick={handleChoose}>Choisir</Button>
            </DialogActions>
        </Dialog>
    );
}
