import { apiFetch } from "./apiFetch"

function _formatBody({startDate, repetition, editRepetition, id}) {
    if (repetition) {
        return {
            availabilityStartDate: startDate,
            repetitionStartDate: startDate,
        }
    } else if (editRepetition) {
        return {
            availabilityStartDate: startDate,
            availabilityId: id,
            modifySerie: editRepetition === 'all'
        }
    }
    return {
        startAt: startDate,
    }
}

export async function fetchAvailability({route, startDate, repetition, isUpdate, editRepetition, id}) {
    const body = _formatBody({startDate, repetition, editRepetition, id})

    const newAvailability = await apiFetch(route || '/availabilities', {
        method: isUpdate ? 'PUT' : 'POST',
        body: JSON.stringify(body)
    })
    return newAvailability
}

export async function deleteAvailability(id, route, availabilityId, editRepetition) {
    let body = null
    if (editRepetition) {
        body = {
            availabilityId: availabilityId,
            modifySerie: editRepetition === 'all',
        }
    }

    const deleteAvailability = await apiFetch(route || `${id}`, {
        method: 'DELETE',
        body: JSON.stringify(body)
    })
    return deleteAvailability
}