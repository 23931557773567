import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import UploadImg from '../../components/UploadImg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCompanies({ open, handleClose, handleAdd, name, setName, setPhoto, error, disable, selected, address, setAddress}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="companies-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                {selected ? 'Modifier' : 'Ajouter'} une entreprise
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="companies-popup-description" component='div'>
                    <TextField error={!!error.name} helperText={error.name} style={{marginTop: 10, marginRight: 60}} label='Nom' id='name' value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField error={!!error.address} helperText={error.address} style={{marginTop: 10}} label='Adresse' id='address' value={address} onChange={(e) => setAddress(e.target.value)} />
                    <div style={{marginTop: 20, marginLeft: -10}}>
                        <UploadImg title='Ajouter un Logo' setPhoto={setPhoto} />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                <Button onClick={handleAdd} disabled={disable}>{selected ? 'Modifier' : 'Ajouter'}</Button>
            </DialogActions>
        </Dialog>
    );
}
