import { ArrowLeft, PlayArrow, VolumeUp, LibraryBooks } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Paper, Zoom, Grid } from '@mui/material'
import React, { useState } from 'react'
import { apiFetch } from '../../../service/security/apiFetch'
import Ressource from './Ressource'

export default function Ressources({ zoom, ressources, setRessources }) {
    const [ressource, setRessource] = useState(false)

    const handleFetchResource = async (item) => {
        const getResource = await apiFetch(`/resources/${item.uuid}/download`, {method: 'GET'}, false, false, false, false, true)
        if (getResource && getResource.signedUrl) {
            setRessource({awsResource: getResource, item: item, ressources: ressources})
        }
    }

    return (
        <>
            {!ressource ? <Zoom in={zoom} timeout={700}>
                <Paper elevation={16} sx={{ p: 3}}>
                    <Button color='secondary' startIcon={<ArrowLeft />} onClick={() => setRessources(false)}>Retour</Button>
                    <Grid container spacing={3} style={{textAlign: 'right', marginTop: 20}}>
                        <Grid item xs={12} sm={5}>
                            <img src={ressources.main.icon.contentUrl} alt={ressources.main.name} width={'130px'}/>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{textAlign: 'left', marginLeft: 50, marginTop: 10}}>
                            <Typography variant='h3' color='secondary' style={{fontWeight: 'bold'}}>
                                {ressources.main.name.toUpperCase()}
                            </Typography>
                            <Typography variant='h4' color='primary' style={{fontWeight: 'bold'}}>
                                {ressources.ressources.length} RESSOURCE(S)
                            </Typography>
                        </Grid>
                        <Typography variant='h6' color='secondary' sx={{fontStyle: 'italic', textAlign: 'left', marginTop: 2, marginLeft: {sm: 12.5, xs: 2}, marginRight: {sm: 12.5, xs: 2}}}>
                            {ressources.main.description}
                        </Typography>
                    </Grid>
                    {ressources && ressources.ressources && ressources.ressources.map((el, index) => (
                        <Grid container spacing={3} key={index} style={{textAlign: 'right', marginTop: 20, marginLeft: 0, width: '100%'}}>
                            <Grid item xs={12} sm={4} sx={{marginTop: 4, marginBottom: 4, textAlign: 'center',  backgroundColor: el.type.name === 'Vidéo' ? '#ECA914' : el.type.name === 'Podcast' ? '#01304A' : 'white', height: 152, borderTopRightRadius: 10, borderBottomRightRadius: 10, boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)', border: '1px solid rgba(236, 169, 20, 0.5)'}}>
                                <Typography variant='h4' color={el.type.name === 'Vidéo' ? '' : 'primary'} style={{fontWeight: 'bold', color: el.type.name === 'Vidéo' && 'white'}}>
                                    {el.type.name.toUpperCase()}
                                </Typography><br />
                                <Button variant='contained' color='primary' startIcon={el.type.name === 'Vidéo' ? <PlayArrow /> : el.type.name === 'Podcast' ? <VolumeUp /> : <LibraryBooks />} onClick={() => handleFetchResource(el)}>{el.type.name === 'Vidéo' ? 'Play' : el.type.name === 'Podcast' ? 'écouter' : 'lire'}</Button>
                            </Grid>
                            <Grid item xs={12} sm={7} sx={{marginTop: 3, textAlign: 'left'}}>
                                <Typography variant='h5' color='secondary' style={{fontWeight: 'bold'}}>
                                    {el.title}
                                </Typography><br />
                                <Typography variant='body1' color='secondary' style={{fontWeight: 'bold'}}>
                                    {el.description}
                                </Typography><br />
                                <Typography variant='caption' style={{fontWeight: 'bold', color: 'gray'}}>
                                    <span style={{marginRight: 30}}>{el.type.name} - {el.duration} min</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            </Zoom> : 
            <Ressource setRessource={setRessource} ressource={ressource} zoom={zoom} />}
        </>
    )
}