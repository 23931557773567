import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_COMPANIES = 'FETCHING_COMPANIES'
const SET_COMPANIES = 'SET_COMPANIES'
const ADD_COMPANIES = 'ADD_COMPANIES'
const UPDATE_COMPANIES = 'UPDATE_COMPANIES'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function companiesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_COMPANIES:
            return { ...state, loading: true }
        case SET_COMPANIES:
            return {...state, companies: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_COMPANIES:
            return {...state, companies: [action.payload, ...state.companies]}
        case UPDATE_COMPANIES:
            return {...state, companies: action.payload }
        case DELETE_MESSAGE:
            return {...state, companies: state.companies.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useCompanies() {
    const [state, dispatch] = useReducer(companiesReducer, {    
        companies: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        companies: state.companies,
        totalItems: state.totalItems,
        view: state.view,
        fetchCompanies: useCallback(async function (route) {
            if ((state.companies || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_COMPANIES' })
            const companies = await apiFetch(route || '/companies', {method: 'GET'})
            if (companies) {
                dispatch({type: 'SET_COMPANIES', payload: companies})
            }
            return companies
        }, [state]),
        createCompanies: useCallback(async function(data, route) {
            const newCompanies = await apiFetch('/companies', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const companies = await apiFetch(route, {method: 'GET'})
            if (!newCompanies.violations && companies) {
                dispatch({type: 'SET_COMPANIES', payload: companies})
            }
            return newCompanies
        }, []),
        updateCompanies: useCallback(async function(companies, data, route) {
            const newCompanies = await apiFetch('/companies/' + companies, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const companiesUpdated = await apiFetch(route, {method: 'GET'})
            if (!newCompanies.violations && companies) {
                dispatch({type: 'SET_COMPANIES', payload: companiesUpdated})
            }
            return newCompanies
        }, []),
        deleteCompanies: useCallback(async function (companiesId) {
            const discussionDelete = await apiFetch('/companies/' + companiesId, {
                method: 'DELETE'
            }, false, false, true)
            const companiesUpdated = await apiFetch('/companies', {method: 'GET'})
            if (discussionDelete && companiesUpdated) {
                dispatch({type: 'SET_COMPANIES', payload: companiesUpdated})
            }
            return discussionDelete
        }, []),
    }
}