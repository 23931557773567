import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SkillsPopup({ open, handleClose, handleChoose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="skills-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A'}}>
                {open.name && open.name.toUpperCase()}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="skills-popup-description">
                    {open.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
                {handleChoose && <Button onClick={handleChoose}>Choisir</Button>}
            </DialogActions>
        </Dialog>
    );
}
