import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletedConfirmationPopup({ open, handleClose, handleAccept, disable, title }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="delete-popup-title"
            aria-describedby="delete-popup-description"
            style={{zIndex: 1301}}
        >
            <DialogTitle id="delete-popup-title">{title || "Voulez-vous vraiment supprimer ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-popup-description">
                    {title && title.startsWith('Le coaché souhaite') ? '' : 'Cette action est irréversible !'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={disable} onClick={handleClose} color={'primary'}>
                    {title && title.startsWith('Le coaché souhaite') ? 'Oui' : 'Annuler'}
                </Button>
                <Button disabled={disable} onClick={handleAccept} color={'primary'}>
                    {title && title.startsWith('Le coaché souhaite') ? 'Non, il souhaite changer de coach' : 'Confirmer'}
                </Button>
            </DialogActions>
        </Dialog>
  );
}