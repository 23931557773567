import React from "react";
import {
    Dialog,
    Button,
    FormControl,
    InputLabel,
    Select, 
    MenuItem,
    FormHelperText,
    FormLabel,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { DateTimePickerComponent } from "../../components/DateTimePickerComponent";

export default function CustomTuiModal({
    isOpen = false,
    toggle,
    onSubmit,
    submitText = "Save",
    selectedSchedule,
    setStartDate,
    error,
    repetition,
    setRepetition,
    repetitionEndDate,
    setRepetitionEndDate,
    editRepetition,
    setEditRepetition,
    startDate,
}) {

    return (
        <Dialog open={isOpen} onClose={toggle}>
            <div className="tui-full-calendar-popup-container" style={{padding: '17px 55px'}}>
                <div style={{display: 'inline-flex', marginTop: 20}}>
                    <DateTimePickerComponent format={'dd MMMM, yyyy à HH:mm'} showTime={true} text="Date de début" setValue={setStartDate} startDate={startDate}/>
                </div>
                <div style={{display: 'flex', marginTop: 30}}>
                    {!true && <FormControl style={{width: 195}}>
                        <InputLabel htmlFor="repetition">Répétition</InputLabel>
                        <Select
                            labelId="select-repetition"
                            id="select"
                            value={repetition}
                            onChange={(e) => setRepetition(e.target.value)}
                        >
                            <MenuItem aria-label="None" value="" ><em>Sélectionnez une répétition</em></MenuItem>
                            <MenuItem aria-label="day" value="day" >Par jour</MenuItem>
                            <MenuItem aria-label="week" value="week" >Par semaine</MenuItem>
                        </Select>
                    </FormControl>}
                    {repetition && <FormControl style={{width: 195, marginLeft: 30}} error={error.repetition}>
                        <InputLabel htmlFor="repetition">Date de fin de répétition *</InputLabel>
                        <Select
                            value={repetitionEndDate}
                            onChange={(e) => setRepetitionEndDate(e.target.value)}
                        >
                            <MenuItem aria-label="None" value="" ><em>Sélectionnez une fin de répétition</em></MenuItem>
                            <MenuItem aria-label="day" value="1" >Dans 1 mois</MenuItem>
                            <MenuItem aria-label="week" value="3" >Dans 3 mois</MenuItem>
                        </Select>
                        {error.repetition && <FormHelperText style={{color: 'red'}}>Veuillez sélectionner une fin de répétition</FormHelperText> }
                        </FormControl>}
                    {selectedSchedule && selectedSchedule.body.groupHash && <FormControl>
                        <FormLabel component="legend">Modification</FormLabel>
                        <FormGroup>
                            <RadioGroup aria-label="availability" name="availability" value={editRepetition} onChange={(e) => setEditRepetition(e.target.value)}>
                                <FormControlLabel value="only" control={<Radio />} label="Cet évènement seulement" />
                                <FormControlLabel value="after" control={<Radio />} label="Cet évènement et tous les suivants" />
                                <FormControlLabel value="all" control={<Radio />} label="Tous les évènements" />
                            </RadioGroup>
                        </FormGroup>
                    </FormControl>}
                </div>
                <button
                    onClick={toggle}
                    className="tui-full-calendar-button tui-full-calendar-popup-close"
                >
                    <span className="tui-full-calendar-icon tui-full-calendar-ic-close" />
                </button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onSubmit}
                    className="tui-full-calendar-popup-save"
                    style={{marginTop: 20}}
                >
                    <span>{submitText}</span>
                </Button>
            </div>
        </Dialog>
    );
}
