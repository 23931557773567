import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_MANAGERS = 'FETCHING_MANAGERS'
const SET_MANAGERS = 'SET_MANAGERS'
const ADD_MANAGERS = 'ADD_MANAGERS'
const UPDATE_MANAGERS = 'UPDATE_MANAGERS'
const DELETE_MANAGERS = 'DELETE_MANAGERS'

function managersReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_MANAGERS:
            return { ...state, loading: true }
        case SET_MANAGERS:
            return {...state, managers: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_MANAGERS:
            return {...state, managers: [action.payload, ...state.managers]}
        case UPDATE_MANAGERS:
            return {...state, managers: action.payload }
        case DELETE_MANAGERS:
            return {...state, managers: state.managers.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useManagers() {
    const [state, dispatch] = useReducer(managersReducer, {    
        managers: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        managers: state.managers,
        totalItems: state.totalItems,
        view: state.view,
        fetchManagers: useCallback(async function (route) {
            if ((state.managers || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_MANAGERS' })
            const managers = await apiFetch(route || '/managers', {method: 'GET'})
            if (managers) {
                dispatch({type: 'SET_MANAGERS', payload: managers})
            }
            return managers
        }, [state]),
        createManagers: useCallback(async function(data, route) {
            const newManagers = await apiFetch('/managers', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const managers = await apiFetch(route, {method: 'GET'})
            if (!newManagers.violations && managers) {
                dispatch({type: 'SET_MANAGERS', payload: managers})
            }
            return newManagers
        }, []),
        updateManagers: useCallback(async function(managers, data, route) {
            const newManagers = await apiFetch('/managers/' + managers, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const managersUpdated = await apiFetch(route, {method: 'GET'})
            if (!newManagers.violations && managers) {
                dispatch({type: 'SET_MANAGERS', payload: managersUpdated})
            }
            return newManagers
        }, []),
        deleteManagers: useCallback(async function (managersId, route) {
            const managerDelete = await apiFetch('/managers/' + managersId, {
                method: 'DELETE'
            }, false, false, true)
            const managersUpdated = await apiFetch(route || '/managers', {method: 'GET'})
            if (managerDelete && managersUpdated) {
                dispatch({type: 'SET_MANAGERS', payload: managersUpdated})
            }
            return managerDelete
        }, []),
    }
}