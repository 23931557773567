import { Snackbar } from '@mui/material';
import React from 'react'
import MuiAlert from '@mui/material/Alert';

export default function AlertSnackbar({ open, transition, message, setOpen, severity }) {

    const handleClose = () => {
        setOpen(false)
    };
    
    return (
        <>
        {open && message && <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionComponent={transition}
            key={transition ? transition.name : ''}
        >
            <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={severity} >{message}</MuiAlert>
        </Snackbar>}
        </>
    )
}