import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_STUDENTS = 'FETCHING_STUDENTS'
const SET_STUDENTS = 'SET_STUDENTS'
const ADD_STUDENTS = 'ADD_STUDENTS'
const UPDATE_STUDENTS = 'UPDATE_STUDENTS'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function studentsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_STUDENTS:
            return { ...state, loading: true }
        case SET_STUDENTS:
            return {...state, students: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_STUDENTS:
            return {...state, students: [action.payload, ...state.students]}
        case UPDATE_STUDENTS:
            return {...state, students: action.payload }
        case DELETE_MESSAGE:
            return {...state, students: state.students.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useStudents() {
    const [state, dispatch] = useReducer(studentsReducer, {    
        students: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        students: state.students,
        totalItems: state.totalItems,
        view: state.view,
        fetchStudents: useCallback(async function (route) {
            if ((state.students || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_STUDENTS' })
            const students = await apiFetch(route || '/students', {method: 'GET'})
            if (students) {
                dispatch({type: 'SET_STUDENTS', payload: students})
            }
            return students
        }, [state]),
        createStudents: useCallback(async function(data, route) {
            const newStudents = await apiFetch('/students', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const students = await apiFetch(route, {method: 'GET'})
            if (!newStudents.violations && students) {
                dispatch({type: 'SET_STUDENTS', payload: students})
            }
            return newStudents
        }, []),
        updateStudents: useCallback(async function(students, data, route) {
            const newStudents = await apiFetch('/students/' + students, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const studentsUpdated = await apiFetch(route, {method: 'GET'})
            if (!newStudents.violations && students) {
                dispatch({type: 'SET_STUDENTS', payload: studentsUpdated})
            }
            return newStudents
        }, []),
        deleteStudents: useCallback(async function (studentsId, route) {
            const toolboxDelete = await apiFetch('/students/' + studentsId, {
                method: 'DELETE'
            }, false, false, true)
            const studentsUpdated = await apiFetch(route || '/students', {method: 'GET'})
            if (toolboxDelete && studentsUpdated) {
                dispatch({type: 'SET_STUDENTS', payload: studentsUpdated})
            }
            return toolboxDelete
        }, []),
    }
}