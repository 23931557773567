import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyle = makeStyles((theme) => ({
    progressBar: {
        width: '400px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '200px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '300px',
        },
    }
}))

const useAdminStyle = makeStyles(() => ({
    progressBar: {
        width: '215px',
        marginTop: 5,
        marginLeft: 320
    }
}))

export default function ProgressBar({ style, customStyle, isAdmin }) {
    const classes = useStyle()
    const AdminClasses  = useAdminStyle()

    return (
        <div 
            className={isAdmin ? AdminClasses.progressBar : classes.progressBar} 
            style={
                customStyle ? 
                    {width: customStyle.width, 
                    marginTop: customStyle.marginTop, 
                    marginLeft: customStyle.marginLeft
                } : {}
            }>
            {style && <div style={{width: `${style.width}%`, backgroundColor: style.color, height: 5}} />}
        </div>
    )
}