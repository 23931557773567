import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import { LinearProgress } from '@mui/material'
import { TransitionLeft } from '../../helper/utils';
import Participant from './Participant';
import Chat from './Chat'
import { Client as ConversationsClient } from "@twilio/conversations";

const Room = ({ setToken, roomName, token, chatToken, handleLogout, setTransition, setOpenSnackbar, handleClose, setOpenClosePopup, setSeverity, setText }) => {
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chatDisable, setChatDisable] = useState(false);
    const [conversations, setConversations] = useState(false);
    const [notification, setNotification] = useState(false);
    const [status, setStatus] = useState({});
    const [coach, setCoach] = useState(false);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const participantConnected = participant => {
            if (participant.state === 'connected') {
                setParticipants(() => {
                    return [participant]
                });
            }
        };
      
        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };
      
        Video.connect(token, {
            name: roomName
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch((e) => {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setText('Erreur lors du lancement de la vidéo, veulliez verifier votre caméra et/ou micro')
            console.warn(e)
            setRoom(null)
            handleLogout()
        });
      
        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            window.conversationsClient = ConversationsClient;
            setStatus({
                statusString: "Connexion en cours..."
            });
            const getConversationsClient = await ConversationsClient.create(chatToken);
                if (getConversationsClient.connectionState !== 'denied' && getConversationsClient.conversations.conversations.entries().next().value) {
                const conv = getConversationsClient.conversations.conversations.entries().next().value[1]
                setConversations(conv)
                setStatus({
                    statusString: "Vous êtes connecté",
                    status: "default"
                });
            }
        
            getConversationsClient.on("connectionStateChanged", (state) => {
                if (state === "connecting")
                    setStatus({
                        statusString: "Connexion en cours...",
                        status: "default"
                    });
                if (state === "connected") {
                    setStatus({
                        statusString: "Vous êtes connecté.",
                        status: "success"
                    });
                }
                if (state === "disconnecting")
                    setStatus({
                        statusString: "Vous êtes sur le point de déconnecté.",
                        conversationsReady: false,
                      status: "default"
                  });
                if (state === "disconnected")
                    setStatus({
                        statusString: "Vous êtes déconnecté.",
                        conversationsReady: false,
                      status: "warning"
                  });
                if (state === "denied")
                    setStatus({
                        statusString: "Impossible de se connecter.",
                        conversationsReady: false,
                      status: "error"
                  });
            });
            getConversationsClient.on("connectionError", (state) => {
                setChatDisable(true)
            });
            getConversationsClient.on("conversationJoined", (conversation) => {
                setStatus({
                    statusString: "Vous êtes connecté",
                    status: "default"
                });
                setConversations(conversation);
            });
            getConversationsClient.on("conversationLeft", (thisConversation) => {
                setConversations([...conversations.filter((it) => it !== thisConversation)]);
            });
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        setNotification(false)
        setOpen(true)
        setTimeout(() => {
            const paper = document.getElementById('messages')
            if(paper) {
                paper.scrollTop = paper.scrollHeight
            }
        }, 100)
    }

    return (
        <>
            <div className="room" style={{textAlign: 'center', display: 'contents', position: 'relative', width: open ? 'calc(100% - 350px)' : '100%'}}>
                {room && (
                    <Participant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                        isLocal={true}
                        setToken={setToken}
                        setOpenClosePopup={setOpenClosePopup}
                        blackScreenIsDisplayed={participants.length === 0}
                        room={room}
                        handleOpen={handleOpen}
                        open={open}
                        status={status}
                        notification={notification}
                    />
                )}
                {!!participants.length ? participants.map(participant => {
                    return <Participant notification={notification} status={status} open={open} handleOpen={handleOpen} room={room} setToken={setToken} setOpenClosePopup={setOpenClosePopup} key={participant.sid} participant={participant} />
                }) : <div style={{backgroundColor: 'black', color: 'white', display: 'grid', marginTop: 79, height: window.innerHeight - 79}}><LinearProgress />Veuillez patienter votre interlocuteur va rejoindre la visioconsultation</div>}
            </div>
            <Chat setNotification={setNotification} user={coach} setCoach={setCoach} open={open} setOpen={setOpen} chatDisable={chatDisable} conversation={conversations} setText={setText} setSeverity={setSeverity} setTransition={setTransition} setOpenSnackbar={setOpenSnackbar} />
        </>
    );
};

export default Room;
