import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_USERS = 'FETCHING_USERS'
const SET_USERS = 'SET_USERS'
const ADD_USERS = 'ADD_USERS'
const UPDATE_USERS = 'UPDATE_USERS'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function usersReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_USERS:
            return { ...state, loading: true }
        case SET_USERS:
            return {...state, users: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_USERS:
            return {...state, users: [action.payload, ...state.users]}
        case UPDATE_USERS:
            return {...state, users: action.payload }
        case DELETE_MESSAGE:
            return {...state, users: state.users.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useUsers() {
    const [state, dispatch] = useReducer(usersReducer, {    
        users: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        users: state.users,
        totalItems: state.totalItems,
        view: state.view,
        fetchUsers: useCallback(async function (route) {
            if ((state.users || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_USERS' })
            const users = await apiFetch(route || '/pros_consultes', {method: 'GET'})
            if (users) {
                dispatch({type: 'SET_USERS', payload: users})
            }
            return users
        }, [state]),
        createUsers: useCallback(async function(data, route) {
            const newUsers = await apiFetch('/pros_consultes', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const users = await apiFetch(route, {method: 'GET'})
            if (!newUsers.violations && users) {
                dispatch({type: 'SET_USERS', payload: users})
            }
            return newUsers
        }, []),
        updateUsers: useCallback(async function(users, data, route) {
            const newUsers = await apiFetch('/users/' + users, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const usersUpdated = await apiFetch(route, {method: 'GET'})
            if (!newUsers.violations && users) {
                dispatch({type: 'SET_USERS', payload: usersUpdated})
            }
            return newUsers
        }, []),
        deleteUsers: useCallback(async function (usersId) {
            const deleteUser = await apiFetch('/pros_consultes/' + usersId, {
                method: 'DELETE'
            })
            const usersUpdated = await apiFetch('/pros_consultes', {method: 'GET'})
            if (deleteUser && usersUpdated) {
                dispatch({type: 'SET_USERS', payload: usersUpdated})
            }
            return deleteUser
        }, []),
    }
}