import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  Typography } from '@mui/material';

export default function CoachingSeancesChart({ data }) {

    return (
        <div>
            <Typography variant="h5" color='secondary' style={{textAlign: 'center', fontSize: 28}}>
                Séances de coaching
            </Typography><br />
            <ResponsiveContainer height={data.length * 30} width="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="mois" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="Nb séances" stroke="#ECA914" activeDot={{ r: 8 }} />
                    <Legend height={5} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
