import { MoreHoriz, Send } from '@mui/icons-material';
import {
    IconButton,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    Zoom,
    Grid,
    Typography,
    CircularProgress,
    Toolbar,
    InputBase,
    alpha,
    FormHelperText,
    Button,
    Paper,
    Menu,
    MenuItem,
    Divider,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState, useEffect, useRef } from 'react';
import ChatMsg from './MessagesItem/ChatMsg';
import NewMessage from './MessagesItem/NewMessage';
import SearchIcon from '@mui/icons-material/Search';
import { useMessage } from '../hook/discussions';
import { TransitionLeft } from '../helper/utils';
import { handleFormError } from '../service/security/error';
import moment from 'moment'
import ReplyIcon from '@mui/icons-material/Reply';
import useWidth from '../hook/useWith';
import { hasRoles } from '../service/security/hasRoles';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white !important',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(0),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 2),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
    },
}));
  

export default function Messages({ zoom, user, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [newMessage, setNewMessage] = useState('')
    const [openNewMessage, setOpenNewMessage] = useState(false)
    const [hasChatSelected, setHasChatSelected] = useState(false)
    const [answer, setAnswer] = useState(false)
    const inputRef = useRef()
    const [isUpdate, setIsUdate] = useState(false)
    const [error, setError] = useState(false)
    const [disable, setDisable] = useState(false)
    const [searchTab, setSearchTab] = useState(false)
    const [refresh, setRefresh] = useState(null);
    const [anchorElAnswer, setAnchorElAnswer] = React.useState(null);
    const openAnswer = Boolean(anchorElAnswer);
    document.title = 'Wellmonday - Messagerie'

    const width = useWidth()

    const handleClickAnswer = (event) => {
        setAnchorElAnswer(event.currentTarget);
    };

    const handleCloseAnswer = () => {
        setAnchorElAnswer(null);
    };

    const  {
        messages,
        fetchDiscussions,
        createMessages,
        updateMessages,
        deleteMessage,
    } = useMessage()

    
    const handleChangeChat = (param) => {
        setAnswer(false)
        setSearchTab(false)
        setIsUdate(false)
        setRefresh(true)
        setTimeout(() => {
            messages.map((el) => el.isSelected = false)
            const choosenDiscussion = messages.find((el) => el.id === param.id)
            choosenDiscussion.isSelected = true
            setHasChatSelected(choosenDiscussion)
            setRefresh(false)
            const paper = document.getElementById('box')
            paper.scrollTop = paper.scrollHeight
        }, 1)
    }

    useEffect(() => {
        (async () => {
            const messagesFetch = await fetchDiscussions()
            if (messagesFetch && messagesFetch['hydra:member']) {
                messagesFetch['hydra:member'][0].isSelected = true
                setHasChatSelected(messagesFetch['hydra:member'][0])
                const paper = document.getElementById('box')
                paper.scrollTop = paper.scrollHeight
            }
        })()
    }, [fetchDiscussions])

    const handleSendMessage = async (e, force = false) => {
        if (e.keyCode === 13 || force) {
            e.preventDefault()
            setDisable(true)
            setError(false)
            
            let message = null
            if (isUpdate) {
                const messageBody = {
                    content: newMessage,
                }
    
                message = await updateMessages(isUpdate.uuid, messageBody, `/discussions`)
            } else {
                const messageBody = {
                    content: newMessage,
                    discussion: hasChatSelected['@id']
                }
    
                message = await createMessages(messageBody, `/discussions`, true)
            }

            if (!message.newMessages.violations && !message.newMessages['hydra:description']) {
                setHasChatSelected(message.messages && message.messages['hydra:member'].find((disc) => disc.id === hasChatSelected.id))
                const paper = document.getElementById('box')
                paper.scrollTop = paper.scrollHeight
                setSeverity('success')
                setText('Message envoyé !')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            } else {
                setSeverity('error')
                setText(message.newMessages.violations ? 'Problème(s) lors de l\'envoie du message !' : 'Vous ne pouvez envoyer de message dans cette discussion')
                const errorField = message.newMessages.violations ? handleFormError(message, ['message']) : []
                setError(errorField)
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setIsUdate(false)
            setNewMessage('')
            setDisable(false)
            inputRef.current.focus()
        }
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            const newTab = []
            messages.map((el) => {
                if ((el.participants[0] && el.participants[0].firstName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) || (el.participants[0] && el.participants[0].lastName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)) {
                    newTab.push(el)
                }
                return null
            })
            setSearchTab(newTab)
        } else {
            setSearchTab(false)
        }
    }

    const handleChangeMessage = (e) => {
        if (!e.target.value) {
            setIsUdate(false)
        }
        setNewMessage(e.target.value)
    }

    const handleAnswer = () => {
        setAnswer(true)
        setAnchorElAnswer(null)
    }


    const findRecipient = (participants) => {
        if (participants[0] && participants[0].email === user.email) {
            return participants[1] && participants[1].email && `${participants[1].firstName} ${participants[1].lastName}`
        }
        return participants[0].email && `${participants[0].firstName} ${participants[0].lastName}`
    }

    return (
        <Zoom in={zoom} timeout={700}>
            <div>
                <Toolbar sx={{padding: '0px !important', borderBottom: '3px solid #ECA914', marginBottom: 3, position: 'relative'}}>
                    <Typography sx={{flex: {xl: '1 1 100%', lg: '1 1 0%', md: '1 1 0%', sm: '1 1 0%', xs: '1 1 0%', fontWeight: 'bold'}}} variant='h5' color='primary'>Boîte de réception</Typography>
                    {hasRoles() === 'admin' && <Search onChange={handleSearch} style={{position: width === 'xl' || width === 'lg' ? 'relative' : 'absolute', right: 0, marginRight: 0, width: 250}}>
                        <SearchIconWrapper>
                            <SearchIcon style={{color: '#ECA914'}} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher..."
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>}
                </Toolbar>
                <Grid container spacing={3} sx={{height: '100%'}} >
                    <Grid item xs={12} sm={5} style={{borderRight: width !== 'xs' ? '1px solid rgba(0, 0, 0, 0.1)' : '',position: 'relative', height: width === 'xs' ? '100%' : 800, maxHeight: width === 'xs' ? '100%' : 800, overflowY: messages && messages.length > 9 ? 'scroll' : 'hidden', overflowX: 'hidden', padding: 30, paddingTop: 0}}>
                        {!searchTab && !refresh && messages ? messages.map((el, i) => {
                            return (
                                <Grid container spacing={3} key={i} style={{cursor: 'pointer', marginTop: 15, backgroundColor: el.isSelected ? 'rgba(1, 48, 74, 0.3)' : '', borderRadius: 10, paddingBottom: 8}}>
                                    <Grid onClick={() => handleChangeChat(el)} item xs={4} sm={4} lg={4} md={4} style={{marginTop: 9, padding: 0, marginLeft: 5}}>
                                        <Typography variant='body1'> {el.participants[0] && el.participants[0].email === user.email && el.participants.length > 1 ? `${el.participants[1].firstName} ${el.participants[1].lastName}` : `${el.participants[0] && el.participants.length === 1 && hasRoles() !== 'admin' ? 'Contact pros consulte' : el.participants[0] && el.participants.length > 1 ? `${el.participants[0].firstName} ${el.participants[0].lastName}` : hasRoles() === 'admin' ? `${el.participants[0].firstName} ${el.participants[0].lastName}` : 'Pas de participants...'}`}</Typography>
                                    </Grid>
                                    {(width === 'xl' || width === 'lg') && <Grid onClick={() => handleChangeChat(el)} item xs={4} sm={4} lg={4} md={4} style={{marginTop: 9, padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        <Typography variant='body1'> {!!el.messages.length ? el.messages.at(-1).content : 'Aucun message...'}</Typography>
                                    </Grid>}
                                    <Grid onClick={() => handleChangeChat(el)} item xs={3} sm={3} lg={3} md={3} style={{marginTop: 9, padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        <Typography variant='body1'> {el.messages[0] && moment(el.messages[0].createdAt).format('DD MMMM YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }) : searchTab && searchTab.map((el, i) => {
                            return (
                                <Grid container spacing={3} key={i} style={{cursor: 'pointer', marginTop: 15, padding: 10, backgroundColor: el.isSelected ? 'rgba(1, 48, 74, 0.3)' : '', borderRadius: 10}}>
                                    <Grid onClick={() => handleChangeChat(el)} item xs={4} sm={4} lg={4} md={4} style={{marginTop: 9, padding: 0}}>
                                        <Typography variant='body1'> {el.participants[0] && el.participants[0].email === user.email && el.participants.length > 1 ? `${el.participants[1].firstName} ${el.participants[1].lastName}` : `${el.participants[0] ? el.participants[0].firstName : 'Pas de participants...'} ${el.participants[0] ? el.participants[0].lastName : ''}`}</Typography>
                                    </Grid>
                                    <Grid onClick={() => handleChangeChat(el)} item xs={4} sm={4} lg={4} md={4} style={{marginTop: 9, padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        <Typography variant='body1'> {!!el.messages.length ? el.messages.at(-1).content : 'Aucun message...'}</Typography>
                                    </Grid>
                                    <Grid onClick={() => handleChangeChat(el)} item xs={3} sm={3} lg={3} md={3} style={{marginTop: 9, padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        <Typography variant='body1'> {el.messages[0] && moment(el.messages[0].createdAt).format('DD MMMM YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        )}
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper elevation={16} sx={{padding: 2,  borderRadius: 7, marginLeft: 1}}>
                            <div style={{textAlign: 'center', marginBottom: 10}}>
                                <Typography variant='caption'>{hasChatSelected.messages && hasChatSelected.messages[0] ? moment(hasChatSelected.messages[0].createdAt).format('DD MMMM YYYY') : ''}</Typography>
                            </div>
                            {hasChatSelected.messages && hasChatSelected.messages[0] && 
                                <>
                                    <Divider />
                                    <Typography variant='body1' style={{marginTop: 10, marginBottom: 5}}>A : {hasChatSelected.participants && hasChatSelected.participants.length === 1 ? 'Contact pros consulte' : findRecipient(hasChatSelected.participants)}</Typography>
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                </>
                            }
                            <div id='box'>
                                {hasChatSelected ? 
                                <>
                                    {hasChatSelected.messages.length ? hasChatSelected.messages.map((chat, index) => {
                                        return (
                                            <ChatMsg
                                                key={index}
                                                side={user.email === chat.user.email ? 'right' : 'left'}
                                                messages={[
                                                    chat
                                                ]}
                                                setNewMessage={setNewMessage}
                                                setIsUdate={setIsUdate}
                                                deleteMessage={deleteMessage}
                                                setOpenSnackbar={setOpenSnackbar}
                                                setText={setText}
                                                setTransition={setTransition}
                                                setSeverity={setSeverity}
                                                setHasChatSelected={setHasChatSelected}
                                                hasChatSelected={hasChatSelected}
                                                user={chat.user.firstName}
                                            />
                                        )
                                    }) : <ChatMsg
                                        side={'left'}
                                        messages={hasChatSelected.participants && hasChatSelected.participants.length === 2 && hasRoles() === 'coach' ?
                                        [
                                            {content: `"Echanges avec votre coaché - Veuillez utiliser cette discussion pour écrire à votre coaché, qui vous répondra dès que possible."`},
                                        ] : hasChatSelected.participants && hasChatSelected.participants.length === 2 && hasRoles() === 'student' ?
                                        [
                                            {content: `"Echanges avec votre coach - Veuillez utiliser cette discussion pour écrire à votre coach, qui vous répondra dès que possible."`},
                                        ] : [
                                            {content: `"SAV - Veuillez utiliser cette discussion pour joindre l'équipe Wellmonday en cas de problème technique. 
                                            Nous vous répondrons dans les meilleurs délais."`},
                                        ]}
                                        noAvatar={true}
                                        setNewMessage={setNewMessage}
                                        setIsUdate={setIsUdate}
                                        deleteMessage={deleteMessage}
                                        setOpenSnackbar={setOpenSnackbar}
                                        setText={setText}
                                        setTransition={setTransition}
                                        setSeverity={setSeverity}
                                        setHasChatSelected={setHasChatSelected}
                                        hasChatSelected={hasChatSelected}
                                    />}
                                </> : <div style={{textAlign: 'center'}}><CircularProgress color='primary' /></div>}
                            </div>
                            {!answer ? <Divider sx={{m: 2}} /> : <div style={{margin: 70}}></div>}
                            <Grid container spacing={3}>
                                {!answer && (width === 'xl' || width === 'lg') ? <>
                                    <Grid item xs={12} sm={4} sx={{textAlign: 'center'}}>
                                        <ReplyIcon style={{width: 40, height: 40, color: '#ECA914'}}/><br />
                                        <Button sx={{fontSize: 11, color: 'white'}} variant='contained' color='secondary' onClick={() => setAnswer(true)}>répondre</Button>
                                    </Grid>
                                </> :
                                !answer && <div style={{margin: 'auto'}}><IconButton style={{position: 'relative'}} onClick={handleClickAnswer}><MoreHoriz /></IconButton></div>}
                            </Grid>
                            {answer && <div style={{display: 'flex'}}><FormControl sx={{width: '100%', position: 'sticky', bottom: 0, marginTop: 1}} variant="outlined">
                                <InputLabel htmlFor="message">Message</InputLabel>
                                <OutlinedInput
                                    ref={inputRef}
                                    autoComplete={'off'}
                                    autoFocus
                                    id="message"
                                    value={newMessage}
                                    onChange={handleChangeMessage}
                                    onKeyDown={handleSendMessage}
                                    error={!!error.message}
                                    disabled={disable}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => handleSendMessage(e, true)}
                                                edge="end"
                                            >
                                                <Send />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Message"
                                />
                                {error.message && <FormHelperText>{error.message}</FormHelperText>}
                            </FormControl>
                            <Button onClick={() => setAnswer(false)} style={{marginTop: 5, marginLeft: 10}}>Annuler</Button>
                            </div>}
                        </Paper>
                    </Grid>
                </Grid>
                <NewMessage open={openNewMessage} handleClose={() => setOpenNewMessage(false)} />
                <Menu
                    id="main-menu"
                    anchorEl={anchorElAnswer}
                    open={openAnswer}
                    onClose={handleCloseAnswer}
                    MenuListProps={{
                        'aria-labelledby': 'main-button',
                    }}
                >
                    <MenuItem onClick={handleAnswer}>Répondre</MenuItem>
                </Menu>
            </div>
        </Zoom>
    )
};
