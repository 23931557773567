import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignePopup({ open, handleClose, disable }) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={true}
            onClose={handleClose}
            aria-describedby="signe-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Vous pouvez signer votre contrat de coaching
            </DialogTitle>
            <DialogContent style={{margin: 'auto', width: '100%', textAlign: 'center'}}>
                <DialogContentText id="signe-popup-description" sx={{m: 2}}>
                    La signature électronique est disponible à la fin du document
                </DialogContentText>
                <iframe
                    id="pandaDoc"
                    title="Signature contrat coaching"
                    width="50%"
                    height="100%"
                    src={open.sessionUrl}>
                </iframe>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleClose} disabled={disable}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
}
