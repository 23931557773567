import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import ProgressBar from '../../components/ProgressBar';
import { progressBarWidth } from '../../helper/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageUsers({ open, handleClose, handleAdd, error, disable, selected, state, setState, setRole, role }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [style, setStyle] = React.useState(false)

    const handleChange = (e, isPassword) => {
        if (isPassword) {
            handleChangeProgressBar(e)
        }
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }
    
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="user-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    {selected ? 'Modifier' : 'Ajouter'} un utilisateur
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="user-popup-description" component='div'>
                        <TextField error={!!error.lastName} helperText={error.lastName} style={{marginLeft: 20, marginTop: 20, marginRight: 60}} label='Nom' id='lastName' name='lastName' value={state.lastName} onChange={handleChange} />
                        <TextField error={!!error.firstName} helperText={error.firstName} style={{marginLeft: 20, marginTop: 20}} label='Prénom' id='firstName' name='firstName' value={state.firstName} onChange={handleChange} />
                        <TextField error={!!error.email} helperText={error.email} style={{marginLeft: 20, marginTop: 20,  marginRight: 60}} label='Email' id='email' name='email' value={state.email} onChange={handleChange} />
                        <TextField error={!!error.password || !!error.plainPassword} helperText={error.password || error.plainPassword} style={{marginLeft: 20, marginTop: 20}} label='Mot de passe' id='password' name='password' value={state.password} onChange={(e) => handleChange(e, true)}  />
                        <ProgressBar style={style} isAdmin={true} />
                        <FormControl sx={{mt: 2, width: 517, ml: 2.5}}>
                            <InputLabel id="select-label">Rôle</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={role}
                                label="Rôle"
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <MenuItem value={'ROLE_ADMIN'}>Administrateur</MenuItem>
                                <MenuItem value={'ROLE_SUPER_ADMIN'}>Super Administrateur</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                    <Button onClick={handleAdd} type='submit' disabled={disable}>{selected ? 'Modifier' : 'Ajouter'}</Button>
                </DialogActions>
        </Dialog>
    );
}
