import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_POSTS = 'FETCHING_POSTS'
const SET_POSTS = 'SET_POSTS'
const ADD_POSTS = 'ADD_POSTS'
const UPDATE_POSTS = 'UPDATE_POSTS'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function postsReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_POSTS:
            return { ...state, loading: true }
        case SET_POSTS:
            return {...state, posts: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_POSTS:
            return {...state, posts: [action.payload, ...state.posts]}
        case UPDATE_POSTS:
            return {...state, posts: action.payload }
        case DELETE_MESSAGE:
            return {...state, posts: state.posts.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function usePosts() {
    const [state, dispatch] = useReducer(postsReducer, {    
        posts: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        posts: state.posts,
        totalItems: state.totalItems,
        view: state.view,
        fetchPosts: useCallback(async function (route, unconnected = false) {
            if ((state.posts || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_POSTS' })
            const posts = await apiFetch(route || '/blog_posts?order[createdAt]', unconnected ? {method: 'GET', headers: {'Content-Type': 'application/json'}} : {method: 'GET'})
            if (posts) {
                dispatch({type: 'SET_POSTS', payload: posts})
            }
            return posts
        }, [state]),
        createPosts: useCallback(async function(data, route) {
            const newPosts = await apiFetch('/blog_posts', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const posts = await apiFetch(route, {method: 'GET'})
            if (!newPosts.violations && posts) {
                dispatch({type: 'SET_POSTS', payload: posts})
            }
            return newPosts
        }, []),
        updatePosts: useCallback(async function(posts, data, route) {
            const newPosts = await apiFetch('/blog_posts/' + posts, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const postsUpdated = await apiFetch(route, {method: 'GET'})
            if (!newPosts.violations && posts) {
                dispatch({type: 'SET_POSTS', payload: postsUpdated})
            }
            return newPosts
        }, []),
        deletePosts: useCallback(async function (postsId) {
            const discussionDelete = await apiFetch('/blog_posts/' + postsId, {
                method: 'DELETE'
            }, false, false, true)
            const postsUpdated = await apiFetch('/blog_posts', {method: 'GET'})
            if (discussionDelete && postsUpdated) {
                dispatch({type: 'SET_POSTS', payload: postsUpdated})
            }
            return discussionDelete
        }, []),
    }
}