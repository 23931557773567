import React, {
    useRef,
    useLayoutEffect,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from "react";
import TuiCalendar from "tui-calendar";
import moment from "moment";
import Autocomplete from '@mui/lab/Autocomplete';
import { hasRoles } from "../../service/security/hasRoles";
import { Button, TextField } from "@mui/material";

import "tui-calendar/dist/tui-calendar.css";

const CustomTuiCalendar = forwardRef(
    (
        {
            height = "0px",
            matches,
            defaultView = "day",
            calendars = [],
            schedules = [],
            isReadOnly = true,
            showSlidebar = false,
            showMenu = false,
            onCreate,
            createText = "New schedule",
            onBeforeCreateSchedule = () => false,
            onBeforeUpdateSchedule = () => false,
            onBeforeDeleteSchedule = () => false,
            filterSchedules,
            setFilterSchedules,
            specialist,
            toggle,
            handleOpenModal,
            specialists,
            defaultProps,
            handleChangeSpecialist,
            selectedSpecialist,
            ...rest
        },
        ref
    ) => {
        const calendarInstRef = useRef(null);
        const tuiRef = useRef(null);
        const [renderRange, setRenderRange] = useState("");
        const [checkedCalendars, setCheckedCalendars] = useState(
            calendars.map((element) => ({ ...element, isChecked: true }))
        );
        
        useImperativeHandle(ref, () => ({
            getAlert() {
                alert("getAlert from Child");
            },
            createSchedule,
            updateSchedule,
            deleteSchedule,
        }));

        const translateDayName = (dayName) => {
            switch (dayName) {
                case 'Sun':
                    return 'Dimanche'
                case 'Mon':
                    return 'Lundi'
                case 'Tue': 
                    return 'Mardi'
                case 'Wed':
                    return 'Mercredi'
                case 'Thu':
                    return 'Jeudi'
                case 'Fri':
                    return 'Vendredi'
                case 'Sat':
                    return 'Samedi'
                default:
                    return dayName
            }
        }
  
        useEffect(() => {
            calendarInstRef.current = new TuiCalendar(tuiRef.current, {
                useDetailPopup: true,
                useCreationPopup: true,
                theme: {
                    'common.border': '2px solid #e5e5e5',
                    'common.backgroundColor': 'white',
                    'common.holiday.color': '#bbb',
                    'common.saturday.color': '#333',
                    'common.dayname.color': '#333',
                    'common.today.color': '#ECA914',
                    
                    // creation guide style
                    'common.creationGuide.backgroundColor': 'rgba(1, 48, 74, 0.05)',
                    'common.creationGuide.border': '2px solid #01304A',
                    
                    // month header 'dayname'
                    'month.dayname.height': '31px',
                    'month.dayname.borderLeft': '2px solid #e5e5e5',
                    'month.dayname.paddingLeft': '10px',
                    'month.dayname.paddingRight': '10px',
                    'month.dayname.backgroundColor': 'inherit',
                    'month.dayname.fontSize': '12px',
                    'month.dayname.fontWeight': 'normal',
                    'month.dayname.textAlign': 'left',
                    
                    // month day grid cell 'day'
                    'month.holidayExceptThisMonth.color': 'rgba(255, 64, 64, 0.4)',
                    'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
                    'month.weekend.backgroundColor': 'inherit',
                    'month.day.fontSize': '14px',
                    
                    // month schedule style
                    'month.schedule.borderRadius': '2px',
                    'month.schedule.height': '24px',
                    'month.schedule.marginTop': '2px',
                    'month.schedule.marginLeft': '8px',
                    'month.schedule.marginRight': '8px',
                    
                    // month more view
                    'month.moreView.border': '2px solid #d5d5d5',
                    'month.moreView.boxShadow': '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
                    'month.moreView.backgroundColor': 'white',
                    'month.moreView.paddingBottom': '17px',
                    'month.moreViewTitle.height': '44px',
                    'month.moreViewTitle.marginBottom': '12px',
                    'month.moreViewTitle.backgroundColor': 'inherit',
                    'month.moreViewTitle.borderBottom': 'none',
                    'month.moreViewTitle.padding': '12px 17px 0 17px',
                    'month.moreViewList.padding': '0 17px',
                    
                    // week header 'dayname'
                    'week.dayname.height': '42px',
                    'week.dayname.borderTop': '2px solid #e5e5e5',
                    'week.dayname.borderBottom': '2px solid #e5e5e5',
                    'week.dayname.borderLeft': 'inherit',
                    'week.dayname.paddingLeft': '0',
                    'week.dayname.backgroundColor': 'inherit',
                    'week.dayname.textAlign': 'left',
                    'week.today.color': '#ECA914',
                    'week.pastDay.color': '#bbb',
                    
                    // week vertical panel 'vpanel'
                    'week.vpanelSplitter.border': '2px solid #e5e5e5',
                    'week.vpanelSplitter.height': '3px',
                    
                    // week daygrid 'daygrid'
                    'week.daygrid.borderRight': '2px solid #e5e5e5',
                    'week.daygrid.backgroundColor': 'inherit',
                    
                    'week.daygridLeft.width': '72px',
                    'week.daygridLeft.backgroundColor': 'inherit',
                    'week.daygridLeft.paddingRight': '8px',
                    'week.daygridLeft.borderRight': '2px solid #e5e5e5',
                    
                    'week.today.backgroundColor': 'rgba(236, 169, 20, 0.05)',
                    'week.weekend.backgroundColor': 'inherit',
                    
                    // week timegrid 'timegrid'
                    'week.timegridLeft.width': '72px',
                    'week.timegridLeft.backgroundColor': 'inherit',
                    'week.timegridLeft.borderRight': '2px solid #e5e5e5',
                    'week.timegridLeft.fontSize': '13px',
                    'week.timegridLeftTimezoneLabel.height': '40px',
                    'week.timegridLeftAdditionalTimezone.backgroundColor': 'white',
                    
                    'week.timegridOneHour.height': '52px',
                    'week.timegridHalfHour.height': '26px',
                    'week.timegridHalfHour.borderBottom': 'none',
                    'week.timegridHorizontalLine.borderBottom': '2px solid #e5e5e5',
                    
                    'week.timegrid.paddingRight': '8px',
                    'week.timegrid.borderRight': '2px solid #e5e5e5',
                    'week.timegridSchedule.borderRadius': '2px',
                    'week.timegridSchedule.paddingLeft': '2px',
                    
                    'week.currentTime.color': '#ECA914',
                    'week.currentTime.fontSize': '14px',
                    'week.currentTime.fontWeight': 'normal',
                    
                    'week.pastTime.color': '#bbb',
                    'week.pastTime.fontWeight': 'normal',
                    
                    'week.futureTime.color': '#333',
                    'week.futureTime.fontWeight': 'normal',
                    
                    'week.currentTimeLinePast.border': '1px dashed #ECA914',
                    'week.currentTimeLineBullet.backgroundColor': '#ECA914',
                    'week.currentTimeLineToday.border': '2px solid #ECA914',
                    'week.currentTimeLineFuture.border': 'none',
                    
                    // week creation guide style
                    'week.creationGuide.color': '#01304A',
                    'week.creationGuide.backgroundColor': 'rgba(236, 169, 20, 0.05)',
                    'week.creationGuide.fontSize': '11px',
                    'week.creationGuide.fontWeight': 'bold',
                    
                    // week daygrid schedule style
                    'week.dayGridSchedule.borderRadius': '2px',
                    'week.dayGridSchedule.height': '24px',
                    'week.dayGridSchedule.marginTop': '2px',
                    'week.dayGridSchedule.marginLeft': '8px',
                    'week.dayGridSchedule.marginRight': '8px'
                },                   
                template: {
                    allday: function (schedule) {
                        return _getTimeTemplate(schedule, true);
                    },
                    alldayTitle: function () {
                        return '<span class="tui-full-calendar-left-content">Toute la journée</span>';
                    },
                    time: function (schedule) {
                        return _getTimeTemplate(schedule, false);
                        // return (
                        //   "<strong>" +
                        //   moment(schedule.start.getTime()).format("HH:mm") +
                        //   "</strong> " +
                        //   schedule.title
                        // );
                    },
                    goingDuration: function (schedule) {
                        return (
                            '<span class="calendar-icon ic-travel-time"></span>' +
                            schedule.goingDuration +
                            "min."
                        );
                    },
                    comingDuration: function (schedule) {
                        return (
                            '<span class="calendar-icon ic-travel-time"></span>' +
                            schedule.comingDuration +
                            "min."
                        );
                    },
                    monthMoreTitleDate: function (date, dayname) {
                        var day = date.split(".")[2];

                        return (
                            '<span class="tui-full-calendar-month-more-title-day">' +
                            day +
                            '</span> <span class="tui-full-calendar-month-more-title-day-label">' +
                            dayname +
                            "</span>"
                        );
                    },
                    monthMoreClose: function () {
                        return '<span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span>';
                    },
                    monthGridHeader: function (dayModel) {
                        var date = parseInt(dayModel.date.split("-")[2], 10);
                        var classNames = ["tui-full-calendar-weekday-grid-date "];

                        if (dayModel.isToday) {
                            classNames.push("tui-full-calendar-weekday-grid-date-decorator");
                        }
                      
                        return (
                            '<span class="' + classNames.join(" ") + '">' + date + "</span>"
                        );
                    },
                    monthGridHeaderExceed: function (hiddenSchedules) {
                        return (
                            '<span class="weekday-grid-more-schedules">+' +
                            hiddenSchedules +
                            "</span>"
                        );
                    },
                    monthGridFooter: function () {
                        return "";
                    },
                    monthGridFooterExceed: function (hiddenSchedules) {
                        return "";
                    },
                    monthDayname: function (model) {
                        return model.label.toString().toLocaleUpperCase();
                    },
                    weekDayname: function (model) {
                        return (
                            '<span class="tui-full-calendar-dayname-name">' +
                            translateDayName(model.dayName) +
                            '</span>&nbsp;&nbsp;<span class="tui-full-calendar-dayname-date">' +
                            model.date +
                            "</span>"
                        );
                    },
                    weekGridFooterExceed: function (hiddenSchedules) {
                        return "+" + hiddenSchedules;
                    },
                    dayGridTitle: function (viewName) {
                        var title = "";
                        switch (viewName) {
                            case "allday":
                                title =
                                    '<span class="tui-full-calendar-left-content">Toute la journée</span>';
                                break;
                            default:
                                break;
                        }
                      
                        return title;
                    },
                    collapseBtnTitle: function () {
                        return '<span class="tui-full-calendar-icon tui-full-calendar-ic-arrow-solid-top"></span>';
                    },
                    // timezoneDisplayLabel: function(timezoneOffset, displayLabel) {
                    //   var gmt, hour, minutes;
                  
                    //   if (!displayLabel) {
                    //     gmt = timezoneOffset < 0 ? "-" : "+";
                    //     hour = Math.abs(parseInt(timezoneOffset / 60, 10));
                    //     minutes = Math.abs(timezoneOffset % 60);
                    //     displayLabel = gmt + getPadStart(hour) + ":" + getPadStart(minutes);
                    //   }
                  
                    //      return displayLabel;
                    // },
                    timegridDisplayPrimaryTime: function (time) {
                        return time.hour + ":" + time.minutes + '0';
                    },
                    timegridCurrentTime: function (timezone) {
                        return moment(timezone.hourmarker._i).format("HH:mm");
                    },
                    popupIsAllDay: function () {
                        return "All Day1";
                    },
                    popupStateFree: function () {
                        return "Free1";
                    },
                    popupStateBusy: function () {
                        return "Busy1";
                    },
                    titlePlaceholder: function () {
                        return "Subject1";
                    },
                    locationPlaceholder: function () {
                        return "Location1";
                    },
                    startDatePlaceholder: function () {
                        return "Start date";
                    },
                    endDatePlaceholder: function () {
                        return "End date";
                    },
                    popupSave: function () {
                        return "Save";
                    },
                    popupUpdate: function () {
                        return "Update1";
                    },
                    popupDetailDate: function (isAllDay, start, end) {
                        const isSameDay = moment(start.toDate()).format('DD') === moment(end.toDate()).format('DD')
                        var endFormat = (isSameDay ? "" : "DD MMMM YYYY - ") + "HH:mm";

                        if (isAllDay) {
                            return (
                                moment(start).format("DD MMMM YYYY") +
                                (isSameDay ? "" : " - " + moment(end).format("DD MMMM YYYY"))
                            );
                        }
                        const splitter = isSameDay ? ' à ' : ' au '
                      
                        return (
                            moment(new Date(start)).format("DD MMMM YYYY - HH:mm") +
                            splitter +
                            moment(new Date(end)).format(endFormat)
                        );
                    },
                    popupDetailLocation: function (schedule) {
                        return schedule.location;
                    },
                    popupDetailUser: function (schedule) {
                        return (schedule.attendees || []).join(", ");
                    },
                    popupDetailState: function (schedule) {
                        if (schedule.bgColor === "#4991cc" || schedule.bgColor === "#d32626" || schedule.bgColor === "#ffca7f") {
                            return "Type de coaching : " + schedule.state;
                        }
                        return null
                    },
                    popupDetailRepeat: function (schedule) {
                        return schedule.recurrenceRule;
                    },
                    popupDetailBody: function (schedule) {
                        return "" + schedule.dueDateClass;
                    },
                    popupEdit: function () {
                        return "Modifier";
                    },
                    popupDelete: function (e) {
                        return e.data.root.calendar && e.data.root.calendar.id === '5' ? 'Accéder à la visio' : "Supprimer";
                    }
                },
                calendars,
                taskView: false,
                ...rest
            });
            setRenderRangeText();

            if (matches) calendarInstRef.current.changeView("day", true);
            
            calendarInstRef.current.clear();
            calendarInstRef.current.createSchedules(filterSchedules, true);
            calendarInstRef.current.render();
            
            calendarInstRef.current.on("beforeCreateSchedule", function (event) {
                onBeforeCreateSchedule(event);
            });
            calendarInstRef.current.on("beforeUpdateSchedule", function (event) {
                onBeforeUpdateSchedule(event);
            });
            calendarInstRef.current.on("beforeDeleteSchedule", function (event) {
                let customEvent = event
                if (event.schedule.body.groupHash) {
                    customEvent = Object.assign(event, {deleteGroup: true})
                }
                onBeforeDeleteSchedule(customEvent);
            });
            calendarInstRef.current.on("clickSchedule", function (event) {
                const topMargin = specialist ? 255 : 230
                
                if (event.schedule.title.startsWith('Réservé')) {
                    const buttons = document.getElementsByClassName("tui-full-calendar-section-button")[0]
                    const button = document.getElementsByClassName("tui-full-calendar-popup-edit")[0]
                    const deleteButton = document.getElementsByClassName("tui-full-calendar-popup-delete")[0]
                    const deleteButtonIcon = document.getElementsByClassName("tui-full-calendar-ic-delete")[0]

                    if(event.calendar && event.calendar.id === '5') {
                        if (event.schedule.body.slots && event.schedule.body.slots[0] && event.schedule.body.slots[0].intervention && event.schedule.body.slots[0].intervention.visioUrl) {
                            button.style.display = "none"
                            deleteButtonIcon.classList.remove('tui-full-calendar-ic-delete')
                            deleteButtonIcon.classList.add('ic-arrow-line-right')
                            deleteButton.style.backgroundColor = '#ffca7f'
                            deleteButton.style.margin = '5px'
                            deleteButton.style.borderRadius = '5px'
                        } else if (buttons) {
                            buttons.style.display = "none"
                        }
                    } else {
                        if (buttons) {
                            buttons.style.display = "none"
                        }
                    }
                }

                // handle marging top of detail popup
                let schedules = document.getElementsByClassName('tui-full-calendar-time-schedule ')
                schedules = Array.from(schedules);
                const findSchedule = schedules.find((schedule) => {
                    return schedule.attributes[0].value === event.schedule.id
                })
                const rect = findSchedule && findSchedule.getBoundingClientRect()
                const detailPopup = document.querySelector('.tui-full-calendar-popup-detail')
                detailPopup.style.cssText = `top:${rect && rect.top - topMargin}px;`
            });
            calendarInstRef.current.on("clickDayname", function (event) {
                // console.log("clickDayname", event);
                if (calendarInstRef.current.getViewName() === "week") {
                    calendarInstRef.current.setDate(new Date(event.date));
                    calendarInstRef.current.changeView("day", true);
                }
            });
        
            calendarInstRef.current.on("clickMore", function (event) {
                // console.log("clickMore", event.date, event.target);
            });
          
            calendarInstRef.current.on("clickTimezonesCollapseBtn", function (
                timezonesCollapsed
            ) {
              // console.log(timezonesCollapsed);
            });
          
            calendarInstRef.current.on("afterRenderSchedule", function (event) {
                // var schedule = event.schedule;
                // var element = calendarInstRef.current.getElement(
                //   schedule.id,
                //   schedule.calendarId
                // );
                // use the element
                // console.log(element);
            });
          
            return () => {
                calendarInstRef.current.destroy();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tuiRef, schedules]);
      
        useLayoutEffect(() => {
            // console.log("before render");
        });
  
        function currentCalendarDate(format) {
            var currentDate = moment([
                calendarInstRef.current.getDate().getFullYear(),
                calendarInstRef.current.getDate().getMonth(),
                calendarInstRef.current.getDate().getDate()
            ]);
          
            return currentDate.format(format);
        }
      
        function setRenderRangeText() {
            var options = calendarInstRef.current.getOptions();
            var viewName = calendarInstRef.current.getViewName();
            
            var html = [];
            if (viewName === "day") {
                html.push(currentCalendarDate("DD MMMM YYYY"));
            } else if (
                viewName === "month" &&
                (!options.month.visibleWeeksCount ||
                    options.month.visibleWeeksCount > 4)
            ) {
                html.push(currentCalendarDate("YYYY.MM"));
            } else {
                html.push(
                    moment(calendarInstRef.current.getDateRangeStart().getTime()).format(
                        "DD MMMM YYYY"
                    )
                );
                html.push(" au ");
                html.push(
                    moment(calendarInstRef.current.getDateRangeEnd().getTime()).format(
                        " DD MMMM YYYY"
                    )
                );
            }
            setRenderRange(html.join(""));
        }
      
        function _getFormattedTime(time) {
            const date = new Date(time);
            const h = date.getHours();
            let m = date.getMinutes();
            if (m === 0) {
                m = '00'
            }
            return `${h}:${m}`;
        }
      
        function _getTimeTemplate(schedule, isAllDay) {
            var html = [];
            
            if (!isAllDay) {
                html.push(
                    "<strong>" + _getFormattedTime(schedule.start) + "</strong> "
                );
            }
            if (schedule.isPrivate) {
                html.push('<span class="calendar-font-icon ic-lock-b"></span>');
                html.push(" Private");
            } else {
                if (schedule.isReadOnly) {
                    html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
                } else if (schedule.recurrenceRule) {
                    html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
                } else if (schedule.attendees.length) {
                    html.push('<span class="calendar-font-icon ic-user-b"></span>');
                } else if (schedule.location) {
                    html.push('<span class="calendar-font-icon ic-location-b"></span>');
                }

                const title = schedule.title.startsWith('Réservé') ? schedule.title : `à ${moment(schedule.end._date).format('HH:mm')}`
                if (schedule.raw) {
                    html.push(" " + title + ' / ' + schedule.raw.coach.user.firstName);
                } else {
                    html.push(" " + title);
                }
            }
            return html.join("");
        }
      
        const handleCheckChieldElement = (event) => {
            const cloneCheckedCalendars = [...checkedCalendars];
            cloneCheckedCalendars.forEach((element) => {
                if (element.id === event.target.value)
                    element.isChecked = event.target.checked;
            });
            setCheckedCalendars(cloneCheckedCalendars);
            filterCalendar(cloneCheckedCalendars);
        };
      
        const filterCalendar = (cloneCheckedCalendars) => {
            const filterCalendars = cloneCheckedCalendars
                .filter((element) => element.isChecked === false)
                .map((item) => item.id);
            const cloneSchedules = filterSchedules.filter((element) => {
                return filterCalendars.indexOf(element.calendarId) === -1;
            });
          
            calendarInstRef.current.clear();
            calendarInstRef.current.createSchedules(cloneSchedules, true);
            calendarInstRef.current.render();
        };
      
        function createSchedule(schedule) {
            calendarInstRef.current.createSchedules([schedule]);
            setFilterSchedules((prevState) => [...prevState, schedule]);
        }
      
        function updateSchedule(schedule, changes) {
            calendarInstRef.current.updateSchedule(
                schedule.id,
                schedule.calendarId,
                changes
            );
            setFilterSchedules((prevState) =>
                prevState.map((item) => {
                    if (item.id === schedule.id) {
                        return { ...item, ...changes };
                    }
                    return item;
                })
            );
        }
      
        function deleteSchedule(schedule) {
            calendarInstRef.current.deleteSchedule(schedule.id, schedule.calendarId);
            setFilterSchedules((prevState) =>
                prevState.filter((item) => item.id !== schedule.id)
            );
        }
      
        return (
            <div>
                {showSlidebar && (
                    <div id="lnb" style={matches ? {display: "none"} : {marginTop: 117}}>
                        {hasRoles() === 'coach' && <div style={{borderBottom: '1px solid #e5e5e5'}}>
                            <Button variant='contained' color='primary' style={{marginBottom: 20}} onClick={toggle}>Ajouter un créneau</Button>
                        </div>}
                        {onCreate && (
                            <div className="lnb-new-schedule">
                                <button
                                    id="btn-new-schedule"
                                    type="button"
                                    className="btn btn-default btn-block lnb-new-schedule-btn"
                                    data-toggle="modal"
                                    onClick={onCreate}
                                >
                                    {createText}
                                </button>
                            </div>
                        )}
                        <div style={{maxWidth: 400}}>
                            {(hasRoles() === 'super_admin') && specialists && defaultProps &&
                                <Autocomplete
                                    {...defaultProps}
                                    id="specialist"
                                    value={selectedSpecialist}
                                    onChange={handleChangeSpecialist}
                                    renderInput={(params) => <TextField {...params} label="Filtrer par spécialiste" margin="normal" />}
                                />
                            }
                        </div>
                        <div id="lnb-calendars" className="lnb-calendars">
                            <div id="calendarList" className="lnb-calendars-d1">
                                {checkedCalendars.map((element, i) => {
                                    return (
                                        <div key={i} className="lnb-calendars-item">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    className="tui-full-calendar-checkbox-round"
                                                    defaultValue={element.id}
                                                    checked={element.isChecked}
                                                    onChange={(e) => {
                                                        handleCheckChieldElement(e);
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        borderColor: element.bgColor,
                                                        backgroundColor: element.isChecked
                                                            ? element.bgColor
                                                            : "transparent"
                                                    }}
                                                />
                                                <span>{element.name}</span>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                {matches && <Button variant='contained' color='primary' style={{marginBottom: 20}} onClick={handleOpenModal}>Ajouter un RDV en cabinet</Button>}
                <div id="right" style={{marginTop: 121, marginLeft: 16}}>
                    {showMenu && (
                        <div id="menu">  
                            <span id="menu-navi">
                                <button
                                    type="button"
                                    className="btn btn-default btn-sm move-today"
                                    style={{ marginRight: "4px" }}
                                    data-action="move-today"
                                    onClick={() => {
                                        calendarInstRef.current.today();
                                        setRenderRangeText();
                                    }}
                                >
                                    Aujourd'hui
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-default btn-sm move-day"
                                    style={{ marginRight: "4px" }}
                                    data-action="move-prev"
                                    onClick={() => {
                                        calendarInstRef.current.prev();
                                        setRenderRangeText();
                                    }}
                                >
                                    <i
                                        className="calendar-icon ic-arrow-line-left"
                                        data-action="move-prev"
                                    />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-default btn-sm move-day"
                                    style={{ marginRight: "4px" }}
                                    data-action="move-next"
                                    onClick={() => {
                                        calendarInstRef.current.next();
                                        setRenderRangeText();
                                    }}
                                >
                                    <i
                                        className="calendar-icon ic-arrow-line-right"
                                        data-action="move-next"
                                    />
                                </button>
                            </span>
                            <span id="renderRange" className="render-range">
                                {renderRange}
                            </span>
                        </div>
                    )}
                    <div ref={tuiRef} style={{ height }} />
                </div>
            </div>
        );
    }
  );
  
  export default CustomTuiCalendar;
  