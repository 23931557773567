import React from 'react'
import { hasRoles } from '../service/security/hasRoles';
import AdminToolbox from './ToolboxItem/AdminToolbox';
import CoachToolbox from './ToolboxItem/CoachToolbox';
import StudentToolbox from './ToolboxItem/StudentToolbox';

export default function Toolbox({zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop}) {
    document.title = 'Wellmonday - Boite à outils'
    
    return (
        <div>
            {hasRoles() === 'coach' && <CoachToolbox setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
            {hasRoles() === 'student' && <StudentToolbox setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
            {hasRoles() === 'admin' && <AdminToolbox setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
        </div>
    )
}