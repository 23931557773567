import React from 'react'
import CurrentCoaching from './CoachItem/CurrentCoaching'
import WeekCalendar from './CoachItem/WeekCalendar'
import {
    CircularProgress,
} from '@mui/material/';

export default function CoachDashboard({ setOpenSignePopup, zoom, dashboardData, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop, setDashboardData, disable, setOpenExtensionPopup, setOpenConfirmationPopup, setOpenContractPopup }) {
    return (
        <>
            {dashboardData.seances &&
                <WeekCalendar zoom={zoom} seances={dashboardData.seances} />}
            {dashboardData.coachings ?
                <CurrentCoaching setOpenSignePopup={setOpenSignePopup} setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} disable={disable} setOpenExtensionPopup={setOpenExtensionPopup} zoom={zoom} setDashboardData={setDashboardData} setOpenBackdrop={setOpenBackdrop} coachings={dashboardData.coachings} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} /> :
                <div style={{textAlign: 'center', marginTop: 40}}>
                    <CircularProgress />
                </div>
            }
        </>
    )
}