import React from 'react'
import {
    TextField,
    Typography,
    Paper,
    Button,
    Zoom,
    ButtonGroup,
} from '@mui/material';

export default function Motivation({ zoom, setTextArea, textArea, handleNext, handleBack, disable }) {
    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <Paper elevation={16} sx={{maxWidth: 1100, margin: 'auto', textAlign: 'center', p: '0px 100px 20px 100px', }}>
                <Typography variant='h6' sx={{textAlign: 'center', mt: 2, pt: 2}}>
                    Pourriez-vous nous dire en quelques mots, pourquoi vous avez décidé de suivre un coaching ?
                </Typography>
                <TextField
                    id="coaching-area"
                    label="Saisie libre"
                    multiline
                    rows={8}
                    value={textArea}
                    onChange={(e) => setTextArea(e.target.value)}
                    sx={{width: '100%', mt: 5}}
                >
                    
                </TextField>
                <ButtonGroup variant="contained" sx={{height: 50, mt: 3}} >
                    <Button disabled={disable} onClick={handleBack}>revenir à l'étape précédente</Button>
                    <Button disabled={disable} onClick={handleNext} sx={{width: 150}}>Je poursuis</Button>
                </ButtonGroup>
            </Paper>
        </Zoom>
    )
}