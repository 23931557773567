import React, { useEffect, useState } from 'react'
import { apiFetch } from '../service/security/apiFetch';
import { hasRoles } from '../service/security/hasRoles'
import StudentDashboard from './DashboardItem/StudentDashboard';
import CoachDashboard from './DashboardItem/CoachDashboard';
import Coachings from './Coachings';
import AdminDashboard from './DashboardItem/AdminDashboard';
import { CircularProgress } from '@mui/material'

export default function Dashboard({ setOpenSignePopup, coachings, setOpenConfirmationPopup, setOpenContractPopup, setCoachings, zoom, setDashboardData, dashboardData, user, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop, disable, setOpenExtensionPopup }) {
    const [quizHexaco, setQuizHexaco] = useState(false)
    document.title = 'Wellmonday - Tableau de bord'

    useEffect(() => {
        (async () => {
            const fetchDashboardData = await apiFetch('/dashboard')
            if (fetchDashboardData) {
                setDashboardData(fetchDashboardData)
            }

            if (user && user.student && fetchDashboardData && ((fetchDashboardData.currentCoaching && fetchDashboardData.currentCoaching.hexacoQuiz) || (fetchDashboardData.coachings && fetchDashboardData.coachings[fetchDashboardData.coachings.length - 1] && fetchDashboardData.coachings[fetchDashboardData.coachings.length - 1].hexacoQuiz))) {
                const fetchQuizHexaco = await apiFetch(`/hexaco_quizzes/${fetchDashboardData.currentCoaching ? fetchDashboardData.currentCoaching.hexacoQuiz.uuid : fetchDashboardData.coachings[fetchDashboardData.coachings.length - 1].hexacoQuiz.uuid}`)
                if (fetchQuizHexaco.result) {
                    setQuizHexaco(fetchQuizHexaco.result.dimensionResults)
                }
            }
        })()
    }, [setDashboardData, setQuizHexaco, user])

    return (
        <>
            {dashboardData ? 
                hasRoles() === 'student' ? <StudentDashboard dashboardData={dashboardData} zoom={zoom} quizHexaco={quizHexaco} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} /> :
                hasRoles() === 'coach' ? <CoachDashboard setOpenSignePopup={setOpenSignePopup} setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} disable={disable} setOpenExtensionPopup={setOpenExtensionPopup} setDashboardData={setDashboardData} setOpenBackdrop={setOpenBackdrop} dashboardData={dashboardData} zoom={zoom} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} /> :
                hasRoles() === 'manager' ? <Coachings setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} setOpenSignePopup={setOpenSignePopup} coachings={coachings} setCoachings={setCoachings} setOpenBackdrop={setOpenBackdrop} zoom={zoom} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} /> :
                hasRoles() === 'admin' && <AdminDashboard dashboardData={dashboardData} setOpenConfirmationPopup={setOpenConfirmationPopup} setOpenContractPopup={setOpenContractPopup} setOpenSignePopup={setOpenSignePopup} coachings={coachings} setCoachings={setCoachings} setOpenBackdrop={setOpenBackdrop} zoom={zoom} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} />
            : <div style={{width: '100%', textAlign: 'center'}}>
                    <CircularProgress />
                </div>}
        </>
    )
}