import React from 'react'
import { hasRoles } from '../../service/security/hasRoles';
import CoachCoachingDetail from './CoachingDetailItem/CoachCoachingDetail';
import ManagerCoachingDetail from './CoachingDetailItem/ManagerCoachingDetail';
import StudentCoachingDetail from './CoachingDetailItem/StudentCoachingDetail';

export default function CoachingDetail({ zoom, setOpenSnackbar, setText, setTransition, setSeverity, setOpenBackdrop, setOpenExtensionPopup, setOpenConfirmationPopup, setSeancesSuggested, setOpenManageExtensionPopup, setOpenSignePopup, setOpenContractPopup }) {
    document.title = 'Wellmonday - Coaching'

    return (
        <>
            {(hasRoles() === 'coach' || hasRoles() === 'admin') && <CoachCoachingDetail setOpenBackdrop={setOpenBackdrop} setOpenExtensionPopup={setOpenExtensionPopup} setOpenConfirmationPopup={setOpenConfirmationPopup} setSeancesSuggested={setSeancesSuggested} setOpenManageExtensionPopup={setOpenManageExtensionPopup} setOpenSignePopup={setOpenSignePopup} setOpenContractPopup={setOpenContractPopup} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
            {hasRoles() === 'student' && <StudentCoachingDetail setOpenSignePopup={setOpenSignePopup} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
            {hasRoles() === 'manager' && <ManagerCoachingDetail setOpenSignePopup={setOpenSignePopup} setOpenBackdrop={setOpenBackdrop} setOpenSnackbar={setOpenSnackbar} setText={setText} setTransition={setTransition} setSeverity={setSeverity} zoom={zoom} />}
        </>
    )
}