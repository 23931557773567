import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    Avatar
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NextSeanceInformation({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="coachs-popup-description"
        >
            <Avatar src={open ? open.coaching && open.coaching.student && open.coaching.student.photo && open.coaching.student.photo.contentUrl : ''} alt={"Coach"} sx={{margin: 'auto', width: 80, height: 80, mt: 3}} />
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                {open && open.coaching.student.user.firstName} {open && open.coaching.student.user.lastName[0]}. de {open && open.coaching.student.company.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="coachs-popup-description">
                    <span>Le {open && moment(open.availability.startAt).format('DD MMMM yyyy à HH:mm')}</span><br />
                    <span style={{color: '#gray'}}>SoftSkill : </span>{open && open.coaching.softSkill.name}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
