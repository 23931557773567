import React, { useState } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import { useEffect } from 'react';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const config = {
    responsive: true,
    scales: {
        r: {
            pointLabels: {
                display: true,
                centerPointLabels: true,
                font: {
                    size: 15
                }
            },
            min: 0,
            max: 5,
        }
    },
    plugins: {
        legend: {
            position: 'left',
            display: false,
        },
        title: {
            display: true,
            text: 'Résultat du quiz'
        }
    }
};

export function PieRadius({ quizHexaco }) {
    const [data, setData] = useState([])

    useEffect(() => {
        if (quizHexaco) {
            const handleData = {
                labels: ['Sincérité', 'Équité', 'Evitement de la cupidité', 'Modestie', 'Appréhension', 'Anxiété', 'Dépendance', 'Sensibilité', 'Amour propre social', 'Audace sociale', 'Sociabilité', 'Vitalité', 'Indulgence', 'Gentillesse', 'Flexibilité', 'Patience', 'Organisation', 'Diligence', 'Perfectionnisme', 'Prudence', 'Appreciation esthetique', 'Curiosite', 'Creativite', 'Non conventionnalite', 'Altruisme'],
                datasets: [
                    {
                        data: createData(quizHexaco),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                            'rgba(34, 0, 170, 0.6)',
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setData(handleData)
        }
    }, [quizHexaco])

    const createData = (array) => {
        let a = []
        array.map((item) => {
            item.facetteResults.map((el) => {
                return a.push(el.score)
            })
            return null
        })
        return a
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#FCAEBE', width: 50, height: 25, marginRight: 5}}></div>Honnêteté-humilité</div></Grid>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#7EC9FB', width: 50, height: 25, marginRight: 5}}></div>Emotivité</div></Grid>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#FBDE96', width: 50, height: 25, marginRight: 5}}></div>Extraversion</div></Grid>
            </Grid>
            <Grid container spacing={3} sx={{pt: 1}}>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#8DE3E3', width: 50, height: 25, marginRight: 5}}></div>Agréabilité</div></Grid>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#C9AFFC', width: 50, height: 25, marginRight: 5}}></div>Consciencieusité</div></Grid>
                <Grid item xs={12} sm={4}><div style={{display: 'flex'}}><div style={{backgroundColor: '#FCCC9C', width: 50, height: 25, marginRight: 5}}></div>Ouverture à l'expérience</div></Grid>
                <Grid item xs={12} sm={4}><div style={{display: 'flex', marginTop: -16}}><div style={{backgroundColor: '#8F80CC', width: 50, height: 25, marginRight: 5}}></div>Altruisme</div></Grid>
            </Grid>
            <div style={{position: 'relative', margin: 'auto', height: '40vh', width: '40vw', marginTop: -40}}>
                {data.labels && <PolarArea data={data} options={config} id='canvas' />}
            </div>
        </>
    );
}