import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_MESSAGES = 'FETCHING_MESSAGES'
const SET_MESSAGES = 'SET_MESSAGES'
const ADD_MESSAGES = 'ADD_MESSAGES'
const UPDATE_MESSAGES = 'UPDATE_MESSAGES'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function messagesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_MESSAGES:
            return { ...state, loading: true }
        case SET_MESSAGES:
            return {...state, messages: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_MESSAGES:
            return {...state, messages: [action.payload, ...state.messages]}
        case UPDATE_MESSAGES:
            return {...state, messages: action.payload }
        case DELETE_MESSAGE:
            return {...state, messages: state.messages.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useMessage() {
    const [state, dispatch] = useReducer(messagesReducer, {    
        messages: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        messages: state.messages,
        totalItems: state.totalItems,
        view: state.view,
        fetchDiscussions: useCallback(async function (route) {
            if ((state.messages || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_MESSAGES' })
            const messages = await apiFetch(route || '/discussions', {method: 'GET'})
            if (messages) {
                dispatch({type: 'SET_MESSAGES', payload: messages})
            }
            return messages
        }, [state]),
        createMessages: useCallback(async function(data, route) {
            const newMessages = await apiFetch('/messages', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const messages = await apiFetch(route, {method: 'GET'})
            if (!newMessages.violations && messages) {
                dispatch({type: 'SET_MESSAGES', payload: messages})
            }
            return {newMessages: newMessages, messages: messages}
        }, []),
        createDiscussion: useCallback(async function(data) {
            const newDiscussion = await apiFetch('/discussions', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            if (state.messages && !newDiscussion.violations) {
                dispatch({type: 'ADD_MESSAGES', payload: newDiscussion})
            } else if (!newDiscussion.violations && newDiscussion.messages.length) {
                dispatch({type: 'SET_MESSAGES', payload: newDiscussion})
            }
            return newDiscussion
        }, [state]),
        updateMessages: useCallback(async function(messages, data, route) {
            const newMessages = await apiFetch('/messages/' + messages, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const messagesUpdated = await apiFetch(route, {method: 'GET'})
            if (!newMessages.violations && messages) {
                dispatch({type: 'SET_MESSAGES', payload: messagesUpdated})
            }
            return {newMessages: newMessages, messages: messagesUpdated}
        }, []),
        deleteMessage: useCallback(async function (messageId) {
            const discussionDelete = await apiFetch('/messages/' + messageId, {
                method: 'DELETE'
            })
            const messagesUpdated = await apiFetch('/discussions', {method: 'GET'})
            if (discussionDelete && messagesUpdated) {
                dispatch({type: 'SET_MESSAGES', payload: messagesUpdated})
            }
            return {discussionDelete: discussionDelete, messages: messagesUpdated}
        }, []),
    }
}