import { useReducer, useCallback } from "react"
import { apiFetch } from "../service/security/apiFetch"

const FETCHING_TOOLBOXEX = 'FETCHING_TOOLBOXEX'
const SET_TOOLBOXEX = 'SET_TOOLBOXEX'
const ADD_TOOLBOXEX = 'ADD_TOOLBOXEX'
const UPDATE_TOOLBOXEX = 'UPDATE_TOOLBOXEX'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

function toolboxesReducer(state = [], action) {
    switch (action.type) {
        case FETCHING_TOOLBOXEX:
            return { ...state, loading: true }
        case SET_TOOLBOXEX:
            return {...state, toolboxes: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_TOOLBOXEX:
            return {...state, toolboxes: [action.payload, ...state.toolboxes]}
        case UPDATE_TOOLBOXEX:
            return {...state, toolboxes: action.payload }
        case DELETE_MESSAGE:
            return {...state, toolboxes: state.toolboxes.filter((i) => {
                return i.id !== action.payload
            })}
        default:
            return state
    }
}

export function useToolboxes() {
    const [state, dispatch] = useReducer(toolboxesReducer, {    
        toolboxes: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        toolboxes: state.toolboxes,
        totalItems: state.totalItems,
        view: state.view,
        fetchToolboxes: useCallback(async function (route) {
            if ((state.toolboxes || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_TOOLBOXEX' })
            const toolboxes = await apiFetch(route || '/resources', {method: 'GET'})
            if (toolboxes) {
                dispatch({type: 'SET_TOOLBOXEX', payload: toolboxes})
            }
            return toolboxes
        }, [state]),
        createToolboxes: useCallback(async function(data, route) {
            const newToolboxes = await apiFetch('/resources', {
                method: 'POST',
                body: JSON.stringify(data),
            })
            const toolboxes = await apiFetch(route, {method: 'GET'})
            if (!newToolboxes.violations && toolboxes) {
                dispatch({type: 'SET_TOOLBOXEX', payload: toolboxes})
            }
            return newToolboxes
        }, []),
        updateToolboxes: useCallback(async function(toolboxes, data, route) {
            const newToolboxes = await apiFetch('/resources/' + toolboxes, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            const toolboxesUpdated = await apiFetch(route, {method: 'GET'})
            if (!newToolboxes.violations && toolboxes) {
                dispatch({type: 'SET_TOOLBOXEX', payload: toolboxesUpdated})
            }
            return newToolboxes
        }, []),
        deleteToolboxes: useCallback(async function (toolboxId) {
            const toolboxDelete = await apiFetch('/resources/' + toolboxId, {
                method: 'DELETE'
            }, false, false, true)
            const toolboxesUpdated = await apiFetch('/resources', {method: 'GET'})
            if (toolboxDelete && toolboxesUpdated) {
                dispatch({type: 'SET_TOOLBOXEX', payload: toolboxesUpdated})
            }
            return toolboxDelete
        }, []),
    }
}