import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResumePopup({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="resume-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Résumé de votre séance
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="resume-popup-description" sx={{m: 2}} component='div'>
                    <Typography variant="body1"><span style={{fontStyle: 'bold'}}>Durée : </span>{open && open.duration ? `${open.duration} min` : '0 min'}</Typography><br />
                    {open && open.noteSeance && <Typography variant="body1"><span style={{fontStyle: 'bold'}}>Travail à faire : </span>{open.noteSeance.work}</Typography>}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
