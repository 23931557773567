import { isAuth } from "./isAuth"

export function hasRoles() {
    if(isAuth() && getRole()) {
        if (getRole() === 'ROLE_USER') {
            return 'user'
        } else if (getRole() === 'Admin') {
            return 'admin'
        } else if (getRole() === 'SuperAdmin') {
            return 'admin'
        } else if (getRole() === 'prosConsulte') {
            return 'pros-consulte'
        } else if (getRole() === 'Manager') {
            return 'manager'
        } else if (getRole() === 'Coach') {
            return 'coach'
        } else if (getRole() === 'Student') {
            return 'student'
        }
        return null
    }
}

function getRole() {
    return localStorage.getItem('REACT_ROLE') && JSON.parse(localStorage.getItem('REACT_ROLE'))
}