import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContractPopup({ open, handleClose, handleAdd, content, setContent, disable, error}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="contract-popup-description"
        >
            <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                Saisie des objectifs de coaching
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="contract-popup-description" sx={{m: 2}}>
                    Afin de pouvoir générer le contrat de coaching, merci de saisir ci-dessous les objectifs du coaching, qui seront indiqués dans le contrat :
                </DialogContentText>
                <TextField
                    id="multiline-comment-objectives"
                    label=""
                    multiline
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows={6}
                    sx={{m: 2, width: '94%'}}
                    error={!!error.content}
                    helperText={error.content}
                />
                <Typography variant='body2' color='primary' sx={{mr: 3, float: 'right'}}>{400 - content.length < 0 ? '0' : 400 - content.length} caratères restants</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={disable}>Fermer</Button>
                <Button onClick={handleAdd} disabled={disable}>Envoyer</Button>
            </DialogActions>
        </Dialog>
    );
}
