import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Paper,
    Button,
    CircularProgress,
    Zoom,
    tooltipClasses,
    Tooltip
} from '@mui/material';
import SkillsPopup from '../../components/SkillsPopup';
import { apiFetch } from '../../service/security/apiFetch';
import { styled } from "@mui/styles";

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function ChooseSoftSkills({ handleNext, setSelectedSkills, zoom, selectedSkills, disable }) {
    const [openPopup, setOpenPopup] = useState(false)
    const [tab, setTab] = useState(false)

    useEffect(() => {
        (async () => {
            const softSkill = await apiFetch('/soft_skills')
            if (softSkill && softSkill['hydra:member']) {
                setTab(softSkill['hydra:member'])
            }
        })()
    }, [])

    const handleClickIcon = (info) => {
        setOpenPopup(info)
    }

    const handleClose = () => {
        setOpenPopup(false)
    }

    const handleChoose = () => {
        setSelectedSkills(openPopup)
        tab.map((el) => el.isSelected = false)
        const choosenSoftSkills = tab.find((el) => el.name === openPopup.name)
        choosenSoftSkills.isSelected = true
        setTab(tab)

        setOpenPopup(false)
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '500ms' }}>
            <div>
                {tab ? <Paper elevation={16} sx={{maxWidth: 1100, margin: 'auto', textAlign: 'center', pb: 3, }}>
                    <Typography variant='h6' sx={{textAlign: 'center', mt: 2, pt: 2}}>Quelle soft skill souhaitez-vous travailler en priorité lors de ce coaching ?</Typography>
                    <Grid container spacing={4} sx={{textAlign: 'center', mt: 4}}>
                        {tab.map((el, index) => (
                            <Grid key={index} item xs={12} sm={4} md={3} sx={{mb: 4}}>
                                <StyledTooltip title={'Découvrir'}>
                                    <img src={el.isSelected ? el.iconVariant.contentUrl : el.icon.contentUrl} alt={el.name} width={'130px'} style={{cursor: 'pointer'}} onClick={() => handleClickIcon(el)} />
                                </StyledTooltip><br />
                                <Button onClick={() => handleClickIcon(el)}>{el.name}</Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Button variant='contained' sx={{width: 200, height: 50}} disabled={disable} onClick={handleNext}>Je poursuis</Button>
                    <SkillsPopup open={openPopup} handleClose={handleClose} handleChoose={handleChoose} />
                </Paper> : 
                <div style={{textAlign: 'center', marginTop: 25}}>
                    <CircularProgress color='primary' />    
                </div>}
            </div>
        </Zoom>
    )
}