import React from 'react'
import {
    Zoom,
    Paper,
    Typography,
    useMediaQuery,
} from '@mui/material/';
import { PieRadius } from '../../HexacoChart';
import { useTheme } from '@mui/material/styles';

export default function SoftSkills({ zoom, dashboardData, quizHexaco }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '1000ms' }}>
            <Paper elevation={16} sx={{padding: 3,  height: shouldShowPieRadius(dashboardData) && !isMobile ? 810 : 405}}>
                <Typography variant='h3' sx={{fontSize: 28, fontWeight: 'bold', mb: 2, color: '#01304A'}}>
                    Mes soft skills
                </Typography>
                {shouldShowPieRadius(dashboardData) && !isMobile ? 
                <div>
                    <PieRadius quizHexaco={quizHexaco} />
                </div>
                :
                !isMobile ? <Typography variant='body1' sx={{fontSize: 20, mt: 2, padding: 8, textAlign: 'center', height: dashboardData.currentCoaching && dashboardData.currentCoaching.status === 'initializing' ? 309 : 'auto'}}>
                    Autodiagnostic des soft skills non complété
                </Typography> : <Typography variant='body1' sx={{fontSize: 20, mt: 2, padding: 8, textAlign: 'center', height: dashboardData.currentCoaching && dashboardData.currentCoaching.status === 'initializing' ? 309 : 'auto'}}>
                    L'autodiagnostic des soft skills est uniquement disponible depuis l'application mobile ou depuis votre ordinateur. Vous pouvez la télécharger depuis le store de votre téléphone.
                </Typography>}
            </Paper>
        </Zoom>
    )
}

function shouldShowPieRadius(dashboardData) {
    return (dashboardData.currentCoaching && dashboardData.currentCoaching.hexacoQuiz && dashboardData.currentCoaching.hexacoQuiz.status === 'done') ||
    (dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].hexacoQuiz && dashboardData.coachings[dashboardData.coachings.length - 1].hexacoQuiz.status === 'done')
}