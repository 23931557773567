import * as React from 'react';
import {
    Button,
    Zoom,
    TextField,
    FormHelperText,
    Grid,
    Avatar,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Paper,
    Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Editor } from '@tinymce/tinymce-react';
import frLocale from 'date-fns/locale/fr';

export default function AddPost({ zoom, setFileUrl, fileUrl, setIsModified, open, handleClose, handleAdd, error, disable, selected, state, setState, setFiles, files, editorRef, setPhoto, photo}) {
    const [reload, setReload] = React.useState(false);

    const handleChange = (e, isDate) => {
        if (isDate) {
            setState({...state, createdAt: e})
        } else {
            setState({...state, [e.target.name]: e.target.value})
        }
    }

    const handleFileChanged = e => {
        setIsModified(false)
        const inputFiles = document.querySelector("#inputFiles");
        const objectFiles = inputFiles.files;
        const foundFile = Object.keys(objectFiles).map(index => {
            return objectFiles[index]
        });
        setFiles(files => [...files, foundFile])
        setPhoto(files => [...files, foundFile])
        const reader = new FileReader()
        reader.onload = e => {
            setFileUrl(fileUrl => [...fileUrl, e.target.result])
        }
        reader.readAsDataURL(e.target.files[0])
    };

    const handleDeleteFile = lastModified => _ => {
        const updatedFiles = files.filter(f => f[0] ? f[0].lastModified !== lastModified : f.uuid !== lastModified)
        setFiles(updatedFiles)
        setPhoto(updatedFiles)
    };

    const handleChangePosition = (e, f) => {
        setReload(true)
        f.position = e.target.value
        setTimeout(() => {
            setReload(false)
        }, 25)
    }
    
    return (
        <Zoom in={true} timeout={700}>
            <Box sx={{ width: '100%' }}>
                <Paper elevation={16} sx={{ width: '100%', mb: 2, p: 5 }}>
                    <Typography variant='h5' color='secondary' style={{fontWeight: 'bold'}}>{selected ? 'Modifier' : 'Ajouter'} un article</Typography>
                    <TextField error={!!error.title} helperText={error.title} style={{marginTop: 20, marginRight: 60, width: 400}} label='Titre' id='title' name='title' value={state.title} onChange={handleChange} />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
                        <DesktopDatePicker
                            label="Date"
                            inputFormat="DD/MM/YYYY"
                            value={state.createdAt}
                            onChange={(e) => handleChange(e, true)}
                            renderInput={(params) => <TextField {...params} error={false} color='primary' style={{marginTop: 20, width: 220}} />}
                        />
                    </LocalizationProvider>
                    {error.createdAt && <FormHelperText style={{color: 'red'}}>Veuillez sélectionner une date</FormHelperText> }
                    <div style={{marginTop: 20}}>
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={state.content}
                            init={{
                                height: 300,
                                menubar: true,
                                language: 'fr_FR',
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | fontsizeselect | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                relative_urls : false,
                                remove_script_host : 0,
                                convert_urls: false,
                            }}
                        />
                    </div>
                    <div style={{marginTop: 20, marginLeft: 10}}>
                        <Button
                            variant="contained"
                            onClick={() => document.querySelector("#inputFiles").click()}
                            disabled={files.length > 2}
                            size="small"
                            style={{backgroundColor: '#ffae00'}}
                        >
                            <AddIcon/>
                            {'Ajouter une image'}
                        </Button>
                        <input
                            type="file"
                            id="inputFiles"
                            style={{ visibility: "hidden" }}
                            multiple
                            onChange={handleFileChanged}
                            accept={"image/*"}
                        />
                        {!!error.photo && <FormHelperText style={{color: 'red'}}>{error.photo}</FormHelperText> }
                        {!reload && files.length > 0 && 
                            files.map((f,i) => (
                                <React.Fragment key={f[0] ? f[0].lastModified : i}>
                                    <Grid container spacing={3} style={{marginTop: 5}}>
                                        <Grid item xs={12} sm={2}>
                                            <Avatar alt="Avatar" src={fileUrl[i]} style={{width: 56, height: 56, marginRight: 18}}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography>{`${f[0].name}`}</Typography>
                                            <Typography>{`${f[0].type}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={handleDeleteFile(f[0] ? f[0].lastModified : f.uuid)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <FormControl style={{width: 200, marginTop: 10}} error={!!error.position}>
                                        <InputLabel id="simple-select-label">Position</InputLabel>
                                        <Select
                                            labelId="simple-select-label"
                                            id={i.toString()}
                                            value={f.position || ''}
                                            label="Position"
                                            name={i.toString()}
                                            onChange={(e) => handleChangePosition(e,f)}
                                        >
                                            <MenuItem value={'presentation'}>Image de présentation</MenuItem>
                                            <MenuItem value={'content_left'}>Image de gauche</MenuItem>
                                            <MenuItem value={'content_right'}>Image de droite</MenuItem>
                                        </Select>
                                        {!!error.position && <FormHelperText style={{color: 'red'}}>{error.position}</FormHelperText> }
                                    </FormControl>
                                </React.Fragment>
                            )
                        )}
                    </div>
                <div style={{marginTop: 30}}>
                    <Button color={'secondary'} variant={'contained'} onClick={handleClose} disabled={disable} style={{marginRight: 20, color: 'white'}}>Retour</Button>
                    <Button color={'secondary'} variant={'contained'} onClick={handleAdd} type='submit' disabled={disable} style={{color: 'white'}}>{selected ? 'Modifier' : 'Ajouter'}</Button>
                </div>
            </Paper>
        </Box>
    </Zoom>);
}
