import React, { useEffect, useState } from 'react'
import {
    Paper,
    Typography,
    Toolbar,
    Zoom,
    Stepper,
    Step,
    StepLabel
} from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { getArrayOfSeanceEnded } from '../../../helper/utils';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ECA914',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ECA914',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));


export default function Pathway({ zoom, dashboardData }) {
    const [activeStep, setActiveStep] = useState(0)
    const [steps, setSteps] = useState(false)

    useEffect(() => {
        let arr = ['Choix de soft skill', 'Test HEXACO', 'Choix du coach']
        if ((dashboardData.currentCoaching && dashboardData.currentCoaching.nbSeances) || (dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].nbSeances)) {
            if (dashboardData.currentCoaching) {
                for (let i = 0; i < dashboardData.currentCoaching && dashboardData.currentCoaching.nbSeances; i ++){
                    arr.push(getNbsp(i))
                }
            } else {
                for (let i = 0; i < dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].nbSeances; i ++){
                    arr.push(getNbsp(i))
                }
            }
        }
        arr[arr.length - 1] = 'Séance bilan'
        setSteps(arr)
        const seanceEnded = getArrayOfSeanceEnded(dashboardData.currentCoaching ? dashboardData.currentCoaching.seances : dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].seances).length
        setActiveStep(getStep(dashboardData.currentCoaching || (dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1]), true) + seanceEnded)
    }, [dashboardData])

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{mb: 7, pb: 4, }}>
                <Toolbar>
                    <Typography variant='h5' sx={{fontSize: 28, fontWeight: 'bold', flex: '1 1 90%'}} color='secondary'>Mon parcours</Typography>
                    <Typography variant='h6'>Étape {getStep(dashboardData.currentCoaching || (dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1])) + getArrayOfSeanceEnded(dashboardData.currentCoaching ? dashboardData.currentCoaching.seances : dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].seances).length}/{dashboardData.currentCoaching ? dashboardData.currentCoaching.nbSeances + 3 : dashboardData.coachings && dashboardData.coachings[dashboardData.coachings.length - 1] && dashboardData.coachings[dashboardData.coachings.length - 1].nbSeances + 3}</Typography>
                </Toolbar>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} sx={{mt: 2}}>
                    {steps && steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
        </Zoom>
    )
}

function getNbsp(i) {
    switch(i) {
        case 1:
            return ' '
        case 2:
            return '  '
        case 3:
            return '   '
        case 4:
            return '    '
        case 5:
            return '     '
        case 6:
            return '      '
        case 7:
            return '       '
        case 8:
            return '        '
        case 9:
            return '         '
        case 10:
            return '          '
        case 11:
            return '           '
        case 12:
            return '            '
        default:
            return 'Début coaching'
    }
}

function getStep(coaching, force) {
    switch(coaching && coaching.initStep) {
        case 'softskill':
            return force ? 0 : 1 
        case 'expectation':
            return 1
        case 'quiz':
            return 1
        case 'coach':
            return 2
        case 'validated':
            return 3
        default:
            return 0
    }
}