import * as React from 'react';
import { useState, useCallback } from 'react';
import ChatItem from './ChatItem';
import { Box, Drawer, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography, Divider } from '@mui/material';
import { ArrowForward, Send } from '@mui/icons-material';
import { TransitionLeft } from '../../helper/utils';

export default function Chat({ open, setOpen, conversation, user, setTransition, setOpenSnackbar, setSeverity, setText, chatDisable, setCoach, setNotification }) {
    const [newMessage, setNewMessage] = useState('')
    const [isTyping, setIsTyping] = useState(false)
    const [messages, setMessages] = useState([])
    
    const handleSendMessage = async (e, force) => {
        if (conversation) {
            if (e.keyCode === 13 || force) {
                conversation.sendMessage(newMessage);
                const paper = document.getElementById('messages')
                paper.scrollTop = paper.scrollHeight
                setNewMessage('')
            }
        } else if ((e.keyCode === 13 || force) && open) {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setText('Nous sommes désolé, le tchat est temporairement inaccessible !')
        }
    }

    const handleChangeMessage = (e) => {
        setNotification(false)
        setNewMessage(e.target.value)
        conversation.typing()
    }

    const messageAdded = useCallback((m, t) => {
/*         const incMessage = hasRoles() === 'coach' ? m.author === user : m.author !== user
        if (!open && incMessage) {
            setNotification(true)
        } */
        setMessages(oldMessage => [...oldMessage, m])
        const paper = document.getElementById('messages')
        if (paper) {
            paper.scrollTop = paper.scrollHeight
        }
    }, [])

    React.useEffect(() =>{
        (async () => {
            if (conversation) {
                const fetchMessages = await conversation.getMessages()
                if (fetchMessages) {
                    setMessages(fetchMessages.items)
                    setCoach(conversation.participants.entries() && conversation.participants.entries().next().value[1].identity)
                    const paper = document.getElementById('messages')
                    if (paper) {
                        paper.scrollTop = paper.scrollHeight
                    }
                }
                conversation.on('messageAdded', m => messageAdded(m))
                conversation.on('typingStarted', function(participant) {
                    updateTypingIndicator(participant, true)
                })
                conversation.on('typingEnded', function(participant) {
                    updateTypingIndicator(participant, false)
                })
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation])

    const updateTypingIndicator = (participant, indicator) => {
        setIsTyping({participant: participant, indicator: indicator})
    }

    const displayChat = () => (
        <Box
            sx={{ width: 350, padding: 2 }}
            role="chat"
        >
            <div style={{position: 'fixed', width: '17%'}}>
                <div style={{display: 'flex'}}>
                    <Typography variant='h6' color='secondary' sx={{flex: '1 1 100%'}}>Chat</Typography>
                    <IconButton onClick={() => setOpen(false)}><ArrowForward /></IconButton>
                </div>
                <Divider />
            </div>
            <div id='messages' style={{height: window.innerHeight - 145, overflow: 'scroll', marginTop: 50}}>
                {messages && !!messages.length && messages.map((item, index) => {
                    return <ChatItem key={index} message={item} coach={user}/>
                })}
            </div>
            {isTyping.indicator && <div style={{fontSize: 12, marginTop: -10}}><em>{isTyping.participant.identity.split('|')[0]} est en train d'écrire</em></div>}
            <div style={{display: 'flex'}}>
                <FormControl sx={{width: 320, position: 'fixed', bottom: 10}} variant="outlined">
                    <InputLabel htmlFor="message">Message</InputLabel>
                    <OutlinedInput
                        autoComplete={'off'}
                        autoFocus
                        id="message"
                        value={newMessage}
                        onChange={handleChangeMessage}
                        onKeyDown={handleSendMessage}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={(e) => handleSendMessage(e, true)}
                                    edge="end"
                                >
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Message"
                        disabled={chatDisable}
                    />
                </FormControl>
            </div>
        </Box>
    );

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={() => setOpen(false)}
        >
            {displayChat()}
        </Drawer>
    );
}
