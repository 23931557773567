import { Paper, Zoom, Button, Typography, Grid } from '@mui/material'
import React from 'react'
import AudioPlayer from 'material-ui-audio-player';
import { ArrowLeft } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import VideoPlayer from '../../../components/VideoPlayer';
import PdfView from '../../../components/PdfView';

export default function Ressource({ zoom, setRessource, ressource }) {
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                backgroundColor: '#01304A',
                marginTop: 50,
                marginLeft: 30,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
            loopIcon: {
                color: '#ECA914',
                '&.selected': {
                    color: '#ECA914',
                },
                '&:hover': {
                    color: '#ECA914',
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            playIcon: {
                color: '#ECA914',
                '&:hover': {
                    color: '#ECA914',
                },
            },
            replayIcon: {
                color: '#ECA914',
            },
            pauseIcon: {
                color: '#ECA914',
            },
            volumeIcon: {
                color: '#ECA914',
                '&:hover': {
                    color: '#ECA914',
                },
            },
            volumeSlider: {
                color: '#ECA914',
            },
            progressTime: {
                color: '#ECA914',
            },
            mainSlider: {
                color: '#ECA914',
                marginTop: -10,
                '& .MuiSlider-rail': {
                    color: '#ECA914',
                    height: 20,
                    borderRadius: 40,
                },
                '& .MuiSlider-track': {
                    color: '#ECA914',
                    height: 20,
                    borderRadius: 40,
                },
                '& .MuiSlider-thumb': {
                    color: '#ECA914',
                    display: 'none',
                },
            },
        };  
    });

    return (
        <Zoom in={zoom} timeout={700}>
            <Paper elevation={16} sx={{ p: 3}}>
                <Button color='secondary' startIcon={<ArrowLeft />} onClick={() => setRessource(false)}>Retour</Button>
                <Grid container spacing={3} style={{textAlign: 'right', marginTop: 20}}>
                    <Grid item xs={12} sm={3}>
                        <img src={ressource.ressources.main.icon.contentUrl} alt={ressource.ressources.main.name} width={'150px'}/>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{textAlign: 'center', marginLeft: 50, marginTop: 10}}>
                        <Typography variant='h3' color='secondary' style={{fontWeight: 'bold'}}>
                            {ressource.ressources.main.name.toUpperCase()}
                        </Typography>
                        <Typography variant='h4' color='primary' style={{fontWeight: 'bold'}}>
                            {ressource.item.title.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <div style={{marginTop: 50, marginBottom: 50}}>
                    <Typography variant='h5' color='secondary' style={{textAlign: 'center', marginBottom: 30}}>{ressource.item.title}</Typography>
                    <Typography variant='body1' color='secondary' style={{textAlign: 'center', maxWidth: 1100, margin:'auto'}}>{ressource.item.description}</Typography>
                </div>
                <div style={{backgroundColor: '#01304A', padding: 20}}>
                    <Typography variant='h5' color='primary' style={{textAlign: 'center', fontWeight: 'bold'}}>{ressource.item.type.name}</Typography>
                    {ressource.item.type.name === 'Podcast' ? <AudioPlayer
                        elevation={0}
                        width="95%"
                        variation="primary"
                        spacing={3}
                        download={false}
                        useStyles={useStyles}
                        autoplay={false}
                        order="standart"
                        preload="auto"
                        loop={true}
                        src={ressource.awsResource.signedUrl}
                    /> : ressource.item.type.name === 'Vidéo' ? <VideoPlayer videoUrl={ressource.awsResource.signedUrl} />
                    : ressource.item.document.mimeType === 'image/png' || ressource.item.document.mimeType === 'image/jpg' || ressource.item.document.mimeType === 'image/jpeg' ?
                    <div style={{textAlign: 'center', marginTop: 20}}>
                        <img src={ressource.awsResource.signedUrl} alt={ressource.item.title} width={'100%'} />
                    </div> : <PdfView pdfUrl={ressource.awsResource.signedUrl} />}
                </div>
                <div style={{textAlign: 'center', marginTop: 40}}>
                    <Typography variant='caption' style={{fontWeight: 'bold', color: 'gray'}}>
                        <span style={{marginRight: 30}}>{ressource.item.title} - {ressource.item.duration} min</span>
                    </Typography>
                </div>
            </Paper>
        </Zoom>
    )
}