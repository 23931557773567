import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import { Button } from '@mui/material'
  
export default function PdfView({ pdfUrl }) {

    pdfjs.GlobalWorkerOptions.workerSrc = 
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <>
            <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
                <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                <div>
                    <div style={{color: 'white'}}>
                        Page {pageNumber || (numPages ? 1 : '--')} sur {numPages || '--'}
                    </div>
                    <div>
                        <Button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            variant='contained'
                            style={{marginRight: 10}}
                        >
                            Précédent
                        </Button>
                        <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                            variant='contained'
                        >
                            Suivant
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}