import React from "react";
import { ListItem } from "@material-ui/core";
import { hasRoles } from "../../service/security/hasRoles";

export default function ChatItem(props) {
    const { message, coach } = props;
    const isOwnMessage = hasRoles() === 'coach' ? message.author === coach : message.author !== coach ;
    
    return (
        <ListItem style={styles.listItem(isOwnMessage)}>
            <div style={styles.author}>{message.author.split('|')[0]}</div>
            <div style={styles.container(isOwnMessage)}>
                {message.body}
                <div style={styles.timestamp(isOwnMessage)}>
                    {new Date(message.dateCreated.toISOString()).toLocaleString()}
                </div>
            </div>
        </ListItem>
    );
}

const styles = {
    listItem: (isOwnMessage) => ({
        flexDirection: "column",
        alignItems: isOwnMessage ? "flex-end" : "flex-start",
    }),
    container: (isOwnMessage) => ({
        maxWidth: "75%",
        borderRadius: 12,
        padding: 16,
        color: !isOwnMessage ? 'white' : "black",
        fontSize: 18,
        backgroundColor: !isOwnMessage ? "#01304A" : "#ECA914",
    }),
    author: { fontSize: 12, color: "gray" },
    timestamp: (isOwnMessage) => ({ fontSize: 10, color: !isOwnMessage ? 'white' : "black", textAlign: "right", paddingTop: 4 }),
};
